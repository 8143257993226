import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleCustomChangeContacts,
  handleCustomImageChangeContacts,
  setContacts,
  setCustomContacts,
  setSecondaryActions,
  removeCustomContacts,
  removeContacts,
  setContactsOrder,
  setCustomContactsOrder,
} from "../Redux/Slices/FormSlice";
import { Box, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { FaArrowAltCircleRight } from "react-icons/fa";

const DraggableList = ({ buttonNames, type }) => {
  const form = useSelector((state) => state.form);
  const { t } = useTranslation();
  const [inputFields, setInputFields] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [customizableInputs, setCustomizableInputs] = useState([]);
  const dispatch = useDispatch();
  const tex = localStorage.getItem("lann");
  const [isExpanded, setIsExpanded] = useState(false); // State variable for expansion

  useEffect(() => {
    return () => {
      // Cleanup function
      setInputFields([]); // Reset inputFields to an empty array
      setInputValues({}); // Reset inputValues to an empty object
      setCustomizableInputs([]); // Reset customizableInputs to an empty array
    };
  }, []);
  useEffect(() => {
    setInputFields(
      (form.contacts ?? []).map((contact, index) => {
        const matchedButton = buttonNames.find(
          (button) => button.name === contact.type
        );

        return {
          idInthback: contact.idintheback,
          id: contact.type,
          name: contact.type,
          logo: contact?.logo || "",
          placeholder: matchedButton?.placeholder || "",
          value: contact.value,
        };
      })
    );
  }, [form.contacts]);

  useEffect(() => {
    setInputValues(
      form.contacts.reduce((values, contact) => {
        values[contact.type] = contact.value;
        return values;
      }, {})
    );
  }, [form.contacts]);

  useEffect(() => {
    const arrayOfCustomInputs = (form.contactsCustom ?? []).map(
      (customInput) => ({
        id: customInput.id,
        name: customInput.link ? customInput.link : customInput.name,
        logo:
          customInput.logo !== null && customInput.logo !== ""
            ? customInput.logo
            : customInput.image,
      })
    );

    setCustomizableInputs(arrayOfCustomInputs);
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleButtonClick = (buttonName) => {
    const newId = buttonName.name;
    setIsExpanded((prevIsExpanded) => !prevIsExpanded);

    setInputFields((prevInputFields) => [
      ...prevInputFields,
      {
        id: newId,
        name: buttonName.name,
        logo: buttonName.logo,
        placeholder: buttonName.placeholder,
        value: "", // Initialize value as an empty string or set it to a default value
      },
    ]);

    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [newId]: "", // Initialize value as an empty string or set it to a default value
    }));

    if (type === "primary") {
      dispatch(
        setContacts({
          type: newId,
          idInthback: buttonName.id,
          value: "", // Initialize value as an empty string or set it to a default value
          logo: buttonName.logo, // Include the logo when adding a new contact
        })
      );
    }

    if (type === "secondary") {
      dispatch(
        setSecondaryActions({
          type: newId,
          value: "", // Initialize value as an empty string or set it to a default value
          logo: buttonName.logo, // Include the logo when adding a new contact
        })
      );
    }
  };

  const handleInputChange = (id, value) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [id]: value,
    }));

    if (type === "primary")
      dispatch(
        setContacts({
          type: id,

          value,
        })
      );

    if (type === "secondary")
      dispatch(
        setSecondaryActions({
          type: id,
          value,
        })
      );
  };
  const profile = localStorage.getItem("profile");

  const handleInputClose = async (id, idInthback) => {
    setInputFields((prevInputFields) =>
      prevInputFields.filter((field) => field.id !== id)
    );
    setInputValues((prevInputValues) => {
      const { [id]: _, ...rest } = prevInputValues;
      return rest;
    });
    dispatch(removeContacts({ type: id }));
  };
  const handleDragStart = (e, id) => {
    e.dataTransfer.setData("text/plain", id);
  };
  const handleDragStartCustom = (e, index) => {
    e.dataTransfer.setData("text/plain", `custom_${index}`);
  };

  const handleDropCustom = (e, targetIndex) => {
    e.preventDefault();

    const draggedId = e.dataTransfer.getData("text/plain");
    const isCustomInput = draggedId.startsWith("custom_");
    const draggedIndex = parseInt(draggedId.replace("custom_", ""), 10);

    if (isCustomInput) {
      setCustomizableInputs((prevInputs) => {
        const newInputs = [...prevInputs];
        const [draggedItem] = newInputs.splice(draggedIndex, 1);
        newInputs.splice(targetIndex, 0, draggedItem);

        dispatch(
          setCustomContactsOrder({
            newOrder: newInputs.map((input) => input.id),
          })
        );

        return newInputs;
      });
    } else {
      const draggedField = inputFields.find((field) => field.id === draggedId);
      if (draggedField) {
        const newInputFields = [...inputFields];
        const targetField = newInputFields.find(
          (field) => field.id === inputFields[targetIndex].id
        );
        const targetFieldIndex = newInputFields.indexOf(targetField);
        newInputFields.splice(draggedIndex, 1, targetField);
        newInputFields.splice(targetFieldIndex, 1, draggedField);

        setInputFields(newInputFields);

        dispatch(
          setContactsOrder({
            newOrder: newInputFields.map((input) => input.id),
          })
        );
      }
    }
  };

  const handleDrop = (e, targetId) => {
    e.preventDefault();

    const draggedId = e.dataTransfer.getData("text/plain");
    const draggedField = inputFields.find((field) => field.id === draggedId);

    if (draggedField) {
      const newInputFields = inputFields.filter(
        (field) => field.id !== draggedId
      );
      const targetIndex = inputFields.findIndex(
        (field) => field.id === targetId
      );

      newInputFields.splice(targetIndex, 0, draggedField);
      setInputFields(newInputFields);

      // Update positions in the state
      const updatedPositions = {};
      newInputFields.forEach((field, index) => {
        updatedPositions[field.id] = index;
      });

      dispatch(
        setContactsOrder({ newOrder: newInputFields.map((input) => input.id) })
      );
    }
  };

  const handleAddCustomizableInput = () => {
    const newInputName = ""; // Define a default name for the new input

    if (isDuplicateCustomInput(newInputName, -1)) {
      return;
    }
    dispatch(
      setCustomContacts({
        id: `${customizableInputs.length + 1}`,
        name: "",
        logo: "",
        value: "",
      })
    );

    setCustomizableInputs((prevInputs) => {
      return [
        ...prevInputs,
        {
          id: `${prevInputs.length + 1}`,
          name: "",
          logo: "",
          value: "",
        },
      ];
    });
  };

  const handleCustomInputChange = (index, field, value) => {
    if (isDuplicateCustomInput(value, index)) {
      alert("This input already exists. Please enter a unique value.");
      return;
    }
    setCustomizableInputs((prevInputs) => {
      const updatedInputs = [...prevInputs];
      updatedInputs[index][field] = value;

      dispatch(handleCustomChangeContacts({ index, field, value }));

      return updatedInputs;
    });
  };

  const handleRemoveCustomInput = (index) => {
    setCustomizableInputs((prevInputs) =>
      prevInputs.filter((_, i) => i !== index)
    );
    dispatch(removeCustomContacts(index));
  };

  const filteredButtonNames = buttonNames.filter((buttonName) =>
    buttonName?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const groupedButtons = [];
  for (let i = 0; i < filteredButtonNames.length; i++) {
    const buttonPair = filteredButtonNames.slice(i, i + 1);
    groupedButtons.push(buttonPair);
  }

  const handleFileInputChange = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCustomizableInputs((prevInputs) => {
          const updatedInputs = [...prevInputs];
          updatedInputs[index].logo = reader.result;
          dispatch(handleCustomImageChangeContacts({ index, reader }));
          return updatedInputs;
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const filteredOptions = groupedButtons.filter(
    (buttonPair) =>
      !inputFields.some((field) =>
        buttonPair.some((buttonName) => field.name === buttonName.name)
      )
  );
  const optionCount = filteredOptions.length;
  console.log("buttonPair", optionCount);
  const isDuplicateCustomInput = (value, currentIndex) => {
    return customizableInputs.some(
      (input, index) => index !== currentIndex && input.name === value
    );
  };
  const scrollAmount = 100; // Adjust as needed

  const scrollLeft = () => {
    const container = document.getElementById("your-scrollable-div-id");
    container.scrollLeft -= scrollAmount;
  };

  const scrollRight = () => {
    const container = document.getElementById("your-scrollable-div-id");
    container.scrollLeft += scrollAmount;
  };

  return (
    <div>
      <div>
      
        {/**    <div
          style={{ width: "100%", display: "flex", justifyContent: "start" }}
        >
          <input
            type="text"
            placeholder={t("search")}
            value={searchTerm}
            style={{
              border: "none",
              outline: "none",
              padding: "0.5rem",
              backgroundColor: "rgba(255, 255, 255, 0.2)", // Semi-transparent white
              backdropFilter: "blur(10px)", // Apply blur effect
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
              borderRadius: "5px",
              color: "#fff",
              marginBottom: "0.5rem",
              width: "200px",
              fontSize: "1rem",
              letterSpacing: "1px",
              transition: "width 0.4s ease", // Smooth transition for width
              marginLeft: tex==='ar'? "auto":"1rem",
            }}
            onChange={(e) => setSearchTerm(e.target.value)}
            onFocus={(e) => (e.target.style.width = "310px")} // Expand width on focus
            onBlur={(e) => (e.target.style.width = "200px")} // Shrink width on blur
          />
        </div>
        */}
        {optionCount !== -1 ? (
          <div
            className="hide-scrollbar" 
            style={{
              display: "flex",
              justifyContent: tex === "ar" ? "end" : "start",
              width: "full",

              // Take full width of the grid column
            }}
          >
            <div className=" relative w-[98%] h-24">
              <div
                className=" absolute h-[82%] p-2  flex justify-center items-center bg-gray-600  "
                style={{
                  left: 17,
                  borderRadius: "10px",
                  top: "42%",
                  transform: "translateY(-50%)",

                  zIndex: 2,

                  // Style your arrow button
                }}
              >
                <FaArrowAltCircleLeft
                  color="#fff"
                  size={30}
                  onClick={() => scrollLeft()}
                />
              </div>
              <div
                id="your-scrollable-div-id"
                className="hide-scrollbar ml-4 px-[200px]"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderRadius: "10px",
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                  background:
                    "linear-gradient(to right, rgba(229, 205, 121, 0.1), rgba(229, 205, 121, 0.1))",
                  padding: "1rem",
                  backdropFilter: "blur(8px)",
                  border: "1px solid rgba(229, 205, 121, 0.5)",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                  scrollbarWidth: "none",
                  msOverflowStyle: "none",
                  "&::-webkit-scrollbar": {
                    display: "none", // For Chrome, Safari, and Opera
                    position: "relative", // Needed for absolute positioning of child elements
                  },
                }}
              >
                {groupedButtons
                  .filter(
                    (buttonPair) =>
                      !inputFields.some((field) =>
                        buttonPair.some(
                          (buttonName) => field.name === buttonName.name
                        )
                      )
                  )
                  .map((buttonPair, index) => (
                    <div
                      key={index}
                      style={{
                        display: "inline-flex", // Display inline
                        marginRight: "3rem", // Add margin between items
                        marginLeft: "3rem", // Add margin between items
                        cursor: "pointer",
                      }}
                      onClick={handleClose}
                    >
                      {buttonPair.map((buttonName) => (
                        <div
                          key={buttonName.name}
                          style={{
                            display: "flex",
                            gap: "0.3rem",
                            alignItems: "center",
                          }}
                          onClick={() => handleButtonClick(buttonName)}
                        >
                          <img
                            src={buttonName.logo}
                            alt={buttonName.name}
                            style={{
                              width: "2rem",
                              height: "2rem",
                              borderRadius: "25%",
                            }}
                          />
                          <div className="text-white">{buttonName.name}</div>
                        </div>
                      ))}
                    </div>
                  ))}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "2.5rem",
                  }}
                >
                  <button
                    onClick={handleAddCustomizableInput}
                    className="mr-10"
                    style={{
                      background:
                        "linear-gradient(to right, rgba(229, 205, 121, 0.1), rgba(229, 205, 121, 0.1))", // Gradient for golden color
                      backdropFilter: "blur(8px)", // Apply blur effect for glassy look
                      border: "1px solid rgba(229, 205, 121, 0.5)", // Golden border
                      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)", // Shadow for depth
                      padding: "0.4rem 0.6rem",
                      borderRadius: "4px",
                      color: "#fff",
                      cursor: "pointer",
                      width: "160px", // Take full width of the grid column
                      // margin: "0.2rem 0",
                      marginLeft: tex === "ar" ? "10" : "10",
                      fontSize: "1rem",
                      letterSpacing: "0.5px",
                      transition: "transform 0.3s ease", // Smooth transition for interaction
                    }}
                    onMouseOver={(e) =>
                      (e.target.style.transform = "scale(1.05)")
                    } // Scale up on hover
                    onMouseOut={(e) => (e.target.style.transform = "scale(1)")} // Scale down on mouse out
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "0.3rem",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <AddIcon
                        style={{
                          color: "#eee",
                          fontSize: "2rem",
                          // marginRight: "0.5rem",
                        }}
                      />

                      <div className="text-sm whitespace-nowrap">
                        {" "}
                        {t("Add_Extra_Link")}
                      </div>
                    </div>
                  </button>
                </Box>
              </div>
              <div
                className=" absolute h-[82%]  flex justify-center p-2  items-center bg-gray-600  "
                style={{
                  right: 1,
                  borderRadius: "10px",
                  top: "42%",
                  transform: "translateY(-50%)",

                  zIndex: 2,

                  // Style your arrow button
                }}
              >
                <FaArrowAltCircleRight
                  color="#fff"
                  size={30}
                  onClick={() => scrollRight()}
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div>
        {inputFields.map((inputField, indexinput) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: tex === "ar" ? "end" : "start",
              }}
            >
              <div
                key={inputField.id}
                className="input-field"
                draggable
                onDragStart={(e) => handleDragStart(e, inputField.id)}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => handleDrop(e, inputField.id)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "0.5rem",
                  justifyContent: tex === "ar" ? "end" : "start",
                  width: "365px",
                  marginLeft: "1rem",
                  marginRight: tex === "ar" ? "20px" : "0",
                }}
              >
                <img
                  src={inputField.logo}
                  alt="User"
                  style={{
                    width: "2.7rem",
                    height: "2.7rem",
                    zIndex: "20",
                    marginTop: "0.5rem",
                    borderRadius: "25%",
                    marginRight: "0.3rem",
                  }}
                />

                <TextField
                  size="small"
                  label={inputField.name}
                  id="outlined-basic"
                  type="text"
                  value={inputValues[inputField.id] || ""}
                  placeholder={inputField.placeholder}
                  onChange={(e) =>
                    handleInputChange(inputField.id, e.target.value)
                  }
                  className="bg-text"
                  style={{
                    width: "80%",
                    marginTop: "0.53rem",
                  }}
                />
              </div>
              <button
                onClick={() =>
                  handleInputClose(inputField.id, inputField.idInthback)
                }
                style={{
                  //  marginLeft: "0.5rem",
                  background: "transparent",
                  border: "none",
                  color: "#fff",
                  cursor: "pointer",
                  fontSize: "1.4rem",
                  // padding:'10px'
                }}
              >
                X
              </button>
            </div>
          );
        })}

        {inputFields.length !== 0 ? (
          <div className="flex justify-center">
            <div
              style={{
                borderBottom: "1px solid gray",
                marginTop: "1rem",
                marginBottom: "1rem",
                width: "90%",
              }}
            ></div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div>
        {customizableInputs.map((customInput, index) => (
          <div
            style={{
              display: "flex",
              justifyContent: tex === "ar" ? "end" : "start",
              // marginLeft:14
            }}
          >
            <div
              key={`custom_${index}`}
              className="input-field"
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "0.5rem",
                width: "360px",
                marginLeft: "1rem",
              }}
            >
              <label
                htmlFor={`custom_input_${index}`}
                style={{
                  zIndex: "20",

                  borderRadius: "25%",

                  cursor: "pointer",
                }}
              >
                {customInput.logo ? (
                  <img
                    src={customInput.logo}
                    alt="Custom"
                    style={{
                      width: "2.7rem",
                      height: "2.7rem",
                      zIndex: "20",
                      marginTop: "0.5rem",
                      borderRadius: "25%",
                      marginRight: "0.3rem",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <>
                    <div
                      style={{
                        backgroundColor: "gray",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "2.7rem",
                        height: "2.7rem",
                        marginTop: "0.5rem",
                        borderRadius: "25%",
                        marginRight: "0.3rem",
                      }}
                    >
                      <span
                        style={{
                          color: "white",
                          fontSize: "1.5rem",
                          textAlign: "center",
                        }}
                      >
                        +
                      </span>
                    </div>
                    <input
                      id={`custom_input_${index}`}
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleFileInputChange(index, e)}
                      style={{ display: "none" }}
                    />
                  </>
                )}
              </label>
              <TextField
                size="small"
                id={`custom_input_${index}`}
                label={t("custom_input")}
                variant="outlined"
                type="text"
                value={customInput.name}
                onChange={(e) =>
                  handleCustomInputChange(index, "name", e.target.value)
                }
                className="bg-text"
                style={{ width: "80%", marginTop: "0.53rem" }}
              />
            </div>
            <button
              onClick={() => handleRemoveCustomInput(index)}
              style={{
                background: "transparent",
                border: "none",
                color: "#fff",
                cursor: "pointer",
                fontSize: "1.4rem",
              }}
            >
              X
            </button>
          </div>
        ))}

        {customizableInputs.length !== 0 ? (
          <div className="flex justify-center">
            <div
              style={{
                borderBottom: "1px solid gray",
                marginTop: "1rem",
                marginBottom: "1rem",
                width: "90%",
              }}
            ></div>
          </div>
        ) : (
          ""
        )}
      </div>
      </div>

    </div>
  );
};

export default DraggableList;
