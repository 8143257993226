import React from "react";

const StatisticBox = ({ number, name, photoUrl }) => {
  console.log("number", number);
  return (
    <div style={styles.container}>
      <div style={styles.photoContainer}>
        <img src={photoUrl} alt={name} style={styles.photo} />
        <div>{number}</div>
      </div>

      <div style={styles.textContainer}>
        <div style={styles.number}>{number}</div>
        <div style={styles.name}>{name}</div>
      </div>
    </div>
  );
};
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background:
      "linear-gradient(to right, rgba(229, 205, 121, 0.4), rgba(229, 205, 121, 0.7))", // Gradient for golden color
    borderRadius: "12px", // Rounded corners for modern look
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)", // Soft shadow for depth
    padding: "0px 20px",
    maxWidth: "200px", // Restrict maximum width for a better layout
    textAlign: "center", // Center align the text
    gap: "7px", // Gap between elements
    margin: "10px", // Margin around the box
    height: "140px",
  },
  photoContainer: {
    width: "60px", // Adjust size as needed
    height: "60px",
    borderRadius: "50%",
    overflow: "hidden",
  },
  photo: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  number: {
    fontSize: "1.5rem", // Larger font size for the number
    fontWeight: "bold",
    color: "#262626", // Darker color for better readability
    marginBottom: "4px",
  },
  name: {
    fontSize: "1rem", // Slightly smaller font size for the name
    color: "#666",
  },
};

export default StatisticBox;
