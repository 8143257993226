import "./App.css";
import ThemeRoutes from "./Routes/Routes";
import { Box, Stack, ThemeProvider, createTheme } from "@mui/material";
import { ThemeColor } from "./data/Theme";
import { useLocation } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher";
import i18n from "./i18n";
import Logo from "./assets/images/Logo.png";
import { Sidebar2 } from "./Components/Sidebar2";
import { useNavigate } from "react-router-dom";
function App() {
  let navigate=useNavigate()
  const theme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#e5cd79",
      },
      secondary: {
        main: "#F8F4A2",
      },
      lighter: {
        main: "#FCFAD3",
      },
    },
    typography: {
      title: {
        color: "#4e4d49",
        fontSize: "0.9rem",
        display: "block",
      },

      linkSelected: {
        color: "#e5cd79",
        backgroundColor: "#262626",
        fontSize: "0.9rem",
        display: "block",
      },
    },
  });
  const location = useLocation();

  return (
    <div
      className="App"
      style={{ background: ThemeColor.bodyBackground, color: "#4e4d49" }}
    >
      <div className="bg-[#262626] fixed w-screen h-screen -z-10 top-0 left-0 right-0 bottom-0"></div>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <div>
            {location.pathname !== "/" &&
              location.pathname !== "/Forgetpassword" && (
                <Stack
                  p={"1rem"}
                  direction="row"
                  justifyContent={"center"} // Center items along the main axis
                  alignItems={"center"}
                  sx={{
                    backgroundImage:
                      "linear-gradient(to top, #262626, #262626)",
                    position: "relative", // Needed for absolute positioning of the sidebar
                    //  borderBottom:"1px solid gray"
                  }}
                >
                  {location.pathname !== "/" &&
                    location.pathname !== "/Forgetpassword" && (
                      <Box sx={{ position: "absolute", left: 6 }}>
                        <Sidebar2 />
                      </Box>
                    )}
                  <Box onClick={()=>{{navigate('/Steper');window.location.reload()}}}>
                    <img width={"100px"} src={Logo} alt="Logo" />
                  </Box>
                  <Box sx={{ position: "absolute", right: 20 }}>
                    <LanguageSwitcher />
                  </Box>
                </Stack>
              )}

            <div>
              <ThemeRoutes />
            </div>
          </div>
        </I18nextProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
