import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { IoMdContact } from "react-icons/io";
import React, { useEffect, useState } from "react";
import { FaRegCopy } from "react-icons/fa";
import { IoQrCodeOutline } from "react-icons/io5";
import { jsPDF } from 'jspdf';
import { FaFileDownload } from "react-icons/fa";
import { useSelector } from "react-redux";
import qr from './../../../assets/images/qrcode.png'
import copy from './../../../assets/images/copy.png'
import tinycolor from "tinycolor2";
import { IoShareSocial } from "react-icons/io5";
import share from './../../../assets/images/share.png'
import { FaPhoneAlt, FaPlus } from "react-icons/fa";
import { MdEmail, MdOutlineSaveAlt } from "react-icons/md";
import { BiGridAlt } from "react-icons/bi";
import html2canvas from "html2canvas";
import { PDFDocument } from "pdf-lib";
import { saveAs } from "file-saver";
import Modal from "@mui/material/Modal";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import QRCode from "qrcode.react";
import { PiUploadSimpleBold } from "react-icons/pi";
import { BiLogoWhatsapp } from "react-icons/bi";
import ShareIcon from "@mui/icons-material/Share";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import { IoLogoWhatsapp } from "react-icons/io";


import { FaPhoneVolume } from "react-icons/fa";



const ProgressiveImage = ({ lowResSrc, highResSrc }) => {
  const [src, setSrc] = useState(lowResSrc);

  useEffect(() => {
    const img = new Image();
    img.src = highResSrc;
    img.onload = () => {
      setSrc(highResSrc); // Set high-res image once it's loaded
    };
  }, [highResSrc]);

  return (
    <Box
      sx={{
        backgroundImage: `url(${src})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        width: "100px",
        height: "100px",
        borderRadius: "50%",
        margin: "-50px auto 0",
        zIndex: 1000,
        transition: '0.5s filter linear',
        filter: `${src === lowResSrc ? 'blur(20px)' : ''}`,
      }}
    />
  );
};


const Template6 = (profileImagee, profileImageLowRes) => {
  const [textcolor, setTextColor] = useState("#ffffff");
  const [textbottoncolor, setTextBottonColor] = useState("#ffffff");
  const [open, setOpen] = React.useState(false);
  const [oppen, setOppen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const handleCopyLink = () => {
    // Replace 'your_link_here' with the actual link you want to copy
    const linkToCopy = "asd";

    // Create a temporary input element
    const tempInput = document.createElement('input');
    tempInput.value = linkToCopy;
    document.body.appendChild(tempInput);

    // Select and copy the content
    tempInput.select();
    document.execCommand('copy');

    // Remove the temporary input
    document.body.removeChild(tempInput);

    console.log('Link copied to clipboard:', linkToCopy);
    // You can add additional logic or feedback here if needed
  };

  const handleWhatsAppShare = () => {
    // Replace 'your-website-url' with the actual URL you want to share
    const shareUrl = "ads";

    // Construct the WhatsApp share link
    const whatsappLink = `https://wa.me/?text=${encodeURIComponent(shareUrl)}`;

    // Open the link in a new tab/window
    window.open(whatsappLink, '_blank');
  }

   const handleSpeedDialClick = () => {
    // Code to send the SMS with the link goes here
    // For example, you can use the "sms:" protocol to open the default SMS app with a pre-filled message.
    const message = `Check out this link: asd`;
    window.open(`sms:?body=${encodeURIComponent(message)}`, '_blank');
  };

  const handleDownloadPdf = () => {
    // Create a new jsPDF instance
    const pdf = new jsPDF();

    // Add content to the PDF (you can customize this part)
    pdf.text('Hello, this is your PDF!', 10, 10);

    // Save the PDF as a Blob
    const pdfBlob = pdf.output('blob');

    // Create a download link and trigger the download
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(pdfBlob);
    downloadLink.download = 'myPDF.pdf';
    downloadLink.click();
  };

 
  const form = useSelector((state) => state.form);

  //console.log(form);

  const [profileImage, setProfileImage] = useState("");
  const [coverImage, setCoverImage] = useState("");

  // //console.log();

  useEffect(() => {
    if (form?.profileImage) setProfileImage(form.profileImage);
  }, [form?.profileImage]);

  useEffect(() => {
    if (form?.coverImage) setCoverImage(form.coverImage);
  }, [form?.coverImage]);


  useEffect(() => {
    const mainBackgroundColor = form?.colors?.mainBackground;

    if (mainBackgroundColor) {
      const brightness = tinycolor(mainBackgroundColor).getBrightness();

      // Adjust text color based on brightness
      setTextColor(brightness > 128 ? "#000000" : "#ffffff");
    }
  }, [form?.colors?.mainBackground]);

  useEffect(() => {
    const buttonsBackground = form?.colors?.buttonsBackground;

    if (buttonsBackground) {
      const brightness = tinycolor(buttonsBackground).getBrightness();

      // Adjust text color based on brightness
      setTextBottonColor(brightness > 128 ? "#000000" : "#ffffff");
    }
  }, [form?.colors?.mainBackground]);
  const captureScreenshot = () => {
    const targetComponent = document.getElementById("mainComponent"); // Replace with your component's ID
    if (targetComponent) {
      html2canvas(targetComponent)
        .then((canvas) => {
          const link = document.createElement("a");
          link.href = canvas.toDataURL();
          link.download = "screenshot.png";
          link.click();
        })
        .catch((error) => {
          console.error("Error capturing screenshot:", error);
        });
    }
  };
  const saveAsPDF = async () => {
    const targetComponent = document.getElementById("mainComponent");
    if (targetComponent) {
      try {
        const canvas = await html2canvas(targetComponent);
        const imgData = canvas.toDataURL("image/png");

        const pdfDoc = await PDFDocument.create();
        const page = pdfDoc.addPage([canvas.width, canvas.height]);
        const { width, height } = page.getSize();
        const img = await pdfDoc.embedPng(imgData);
        const { width: imgWidth, height: imgHeight } = img.scale(1);

        page.drawImage(img, {
          x: 0,
          y: 0,
          width: imgWidth,
          height: imgHeight,
        });

        const pdfBytes = await pdfDoc.save();

        const blob = new Blob([pdfBytes], { type: "application/pdf" });

        // Use FileSaver.js library to save the blob as a file
        const saveAs = require("file-saver");
        saveAs(blob, "screenshot.pdf");
      } catch (error) {
        console.error("Error saving as PDF:", error);
      }
    }
  };

  const saveToContacts = () => {
    const contact = {
      name: form.basic.firstName + " " + form.basic.lastName,
      mobile: form.basic.phoneNumber || "",
      email: form.basic.email || "",
      address: form.basic.location || "", // You can add the address from the form if available
      organization: form.basic.businessName || "",
      title: form.basic.jobTitle || "",
    };

    const vcard =
      `BEGIN:VCARD\nVERSION:3.0\n` +
      `N:${contact.name};;;\n` +
      `FN:${contact.name}\n` +
      `TEL;CELL:${contact.phone}\n` +
      `TEL;CELL:${contact.mobile}\n` +
      `EMAIL;HOME:${contact.email}\n` +
      `ADR;HOME:${contact.address}\n` +
      `ORG;WORK:${contact.organization}\n` +
      `TITLE:${contact.title}\n` +
      `NOTE:${contact.notes}\n` +
      `END:VCARD`;

    const blob = new Blob([vcard], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);

    if (navigator.share) {
      navigator
        .share({
          title: "New Contacts",
          text: "Save contacts",
          files: [new File([blob], "newcontact.vcf", { type: "text/vcard" })],
        })
        .then(() => {});
    } else {
      const newLink = document.createElement("a");
      newLink.download = `${contact.name}.vcf`;
      newLink.textContent = contact.name;
      newLink.href = url;

      newLink.click();
    }
  };
  
  return (
    <div sx={{ position: "relative" }} id="mainComponent" className="overflow-hidden">
      
        <button className={`absolute z-50 bottom-4 border-2 w-32 h-16 rounded-3xl text-[var(--button-text-color)] font-bold text-md right-4`}
        style={{
          background:form?.colors.buttonsBackground,
          color:textbottoncolor,
          borderColor:textcolor
        }}
        >
          <div className="flex justify-center items-center">
        <div className='flex justify-around items-center text-center Robote text-sm '>
          <div className="flex flex-col">
        <span className=" whitespace-nowrap">Add To</span>
        <span >Contacts</span>
        </div>
        <div className='ml-4 border-2 rounded-full p-2'  style={{
          borderColor:textcolor
        }}>
        <FaPlus size={16} color={textbottoncolor}/>
        </div>
        </div>
        </div>
      </button>
     
     
     
     
       
    <button className='absolute left-3 bottom-2 w-14 h-14 rounded-full flex justify-center items-center text-[var(--button-text-color)] z-50'
   
    >
      
        <Box sx={{ height: 1 , transform: 'translateZ(0px)', flexGrow: 1}}  style={{
               
                bottom: "10px",
                left: "2px",
                whiteSpace: "nowrap",
                zIndex: "0",
                color: `white`,
              }}>
      <SpeedDial
      onClick={()=>setOppen(!oppen)}
        ariaLabel="SpeedDial basic example"
        sx={{ 
          position: 'absolute', 
          bottom: 10, 
          left: 16, 
          zIndex: 40,
      
          
          '& .MuiSpeedDial-fab': { // Targeting the inner FAB component if necessary
            backgroundColor:`${form?.colors.buttonsBackground} !important`,
            '&:hover': {
              backgroundColor:'darkred !important',
            }
          }
        }}
        icon={
          <div style={{ border: '2px solid', borderColor:textcolor  , borderRadius: '50%', padding: '12px' }}>
            <PiUploadSimpleBold size={28} color={textbottoncolor} />
          </div> }
      >
            
         <SpeedDialAction
            icon=<IoQrCodeOutline size={23}/>
          >
          </SpeedDialAction>
          <SpeedDialAction
            icon=<FaRegCopy size={23}/>
            > 
            </SpeedDialAction>
            
            <SpeedDialAction
            icon=<IoShareSocial size={23}/>
            > 
            </SpeedDialAction>
      
      </SpeedDial>
    </Box>  
      </button>




      <Stack
        sx={{ background: form?.colors?.mainBackground, paddingBottom: "40px",position: "relative",  height:'500px'}}
        gap={3}
      >
      
        <Box
          sx={{
            background: form?.colors?.mainBackground,
            borderRadius: "15px",
            position: "relative",
          }}
        >
        <Box
        sx={{
          backgroundImage: `url(${coverImage})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          borderRadius: "25px",
          height: "190px",
          zIndex:'100',
          margin:'14px',
          backgroundColor: '#000',
        }}
      ></Box>
  

      
          <Stack
            maxWidth={"90%"}
            m={"0 auto"}
            gap={"0.1rem"}
            sx={{ textAlign: "center", position: "relative" }}
            
          >
          
          <Box
          sx={{
            backgroundImage: `url(${typeof profileImage === 'string' && profileImage !== ''?profileImage:''})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeate",
            backgroundSize: "cover",
            width: "120px",
            height: "120px",
            borderRadius: "50%",
            backgroundColor: form?.colors.buttonsBackground,
            margin: "-75px auto 0",
            border: "4px solid ",
            borderColor:'#fff',
           

            zIndex: "1000",

            border: "1px solid black"
          }}
        ></Box>
          <div className="relative">
          <div className="border w-full p-3 rounded-3xl absolute -top-10 pt-10 ">
            <Typography color={textcolor} 
             style=
             {{
              fontSize:"1.5rem", 
              width:"full",
              fontFamily:"Robote_bold",
              zIndex:20
              }}>
              {form?.basic?.firstName !== null &&
                form?.basic?.firstName !== "null"? form?.basic?.firstName :''}
                {"\n"}
                {form?.basic?.lastName !== null &&
                  form?.basic?.lastName !== "null"? form?.basic?.lastName :''}            </Typography>
            {form?.basic?.jobTitle !==null && form?.basic?.jobTitle !=='null' &&
            <Typography
              color={textcolor}
              style=
             {{
              fontSize:"0.9rem", 
              fontFamily:"Robote",
              fontWeight:"600",
              width:"full",
              zIndex:20
              }}
            >
              {" "}
              {form?.basic?.jobTitle}
            </Typography>
          }
          {form?.basic?.businessName !==null && form?.basic?.businessName !=='null' &&
            <Typography
              color={textcolor}
              style=
             {{
              marginTop:"-3px",
              fontSize:"0.8rem", 
              fontFamily:"Robote",
              fontWeight:"500",
              width:"full",
              zIndex:20
              }}
            >
              {form?.basic?.businessName}
            </Typography>
          }
          {form?.basic?.location !==null && form?.basic?.location !=='null' &&
            <Typography
              color={textcolor}
              
              style=
             {{
              fontSize:"0.6rem", 
              fontFamily:"Robote",
              fontWeight:"400",
              width:"full",
              zIndex:20
              }}
            >
              {" "}
              {form?.basic?.location}
            </Typography>
          } 
            {form?.basic?.bio !== null && form?.basic?.bio !== 'null' && 
            <Typography mb={"1rem"} color={textcolor} 
            style=
             {{
              fontSize:"0.8rem", 
              fontFamily:"Robote",
              fontWeight:"500",
              width:"full",
              zIndex:20,
              
              }}>
              {form?.basic?.bio}
            </Typography>
          }
          </div>
          </div>
            <Grid my={"1rem"} gap={"3rem"} justifyContent={"center"} marginTop={"100px"} container>
             
                <Grid
                  item
                  sx={{
                    width: "40px",
                    height:'40px',
                    borderRadius: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "1.5rem",
                    border:'2px solid ',
                    borderColor:textcolor,
                  }}
                >
                  <button className='text-[20px]'>
                <IoLogoWhatsapp color={textcolor}/>
              </button>
                </Grid>
              
            
                <Grid
                  item
                  sx={{
                    width: "40px",
                    height:'40px',
                    borderRadius: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "1.5rem",
                    cursor: "pointer", // Add this line to show it's clickable
                    border:'2px solid ',
                    borderColor:textcolor,
                  }}
                  
                >
                  <button className="text-[20px]">
                  <MdEmail color={textcolor}/>
                  </button>
                </Grid>
             
                <Grid
                  item
                  sx={{
                    width: "40px",
                    height:'40px',
                    borderRadius: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "1.5rem",
                    cursor: "pointer", // Add this line to show it's clickable
                    border:'2px solid ',
                    borderColor:textcolor,
                  }}
                >
                  <button className="text-[20px] z-10">
                    <FaPhoneVolume color={textcolor} className=' -rotate-[30deg]' />
                                    </button>
                </Grid>
             
            </Grid>
         
            <Grid gap="45px" justifyContent={"center"} container style={{marginTop:"15px"}}>
              {form?.contacts.map((item) => {
                return (
                  <Grid
                    item
                    sx={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "14px",
                      // background: form.colors.buttonsBackground,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "1.5rem",
                    }}
                  >
                    {item.logo ? (
                      <Box
                        sx={{
                          width: "100%",
                        }}
                      >
                        <img
                          style={{
                            maxWidth: "100%",
                            borderRadius: "10px",
                          }}
                          src={`${item?.logo}`}
                        />
                      </Box>
                    ) : (
                     ''
                    )}
                  </Grid>
                );
              })}

              {form?.contactsCustom.map((item) => {
                //console.log(item);
                return (
                  <Grid
                    item
                    sx={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "14px",
                      // background: form.colors.buttonsBackground,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "1.5rem",
                    }}
                  >
                    {item?.logo && (
                      <Box
                        sx={{
                          backgroundImage: `url(${item?.logo})`,
                          backgroundRepeate: "no-repeate",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          width: "100%",
                          height: "100%",
                          borderRadius: "8px",
                        }}
                      ></Box>
                    )}

                    {item?.image && (
                      <Box
                        sx={{
                          backgroundImage: `url(${item?.image})`,
                          backgroundRepeate: "no-repeate",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          width: "100%",
                          height: "100%",
                          borderRadius: "8px",
                        }}
                      ></Box>
                    )}
                  </Grid>
                );
              })}
            </Grid>

            
  

          </Stack>
        </Box>
        <Stack
          gap={"1rem"}
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        ></Stack>
      </Stack>
    </div>
  );
};

export default Template6;
