import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const statsApi = createApi({
  reducerPath: "profileApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.dev4.gomaplus.tech/api/",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  }),
  endpoints: (builder) => ({
    fetchStats: builder.query({
      query: () => `get_links_with_visit`,
    }),
  }),
});

export const { useFetchStatsQuery } = statsApi;
