import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setColors } from "../Redux/Slices/FormSlice";
import { useTranslation } from "react-i18next";

const ColorPicker = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { colors } = useSelector((state) => state.form);
  const tex = localStorage.getItem("lann");

  // Define your color palettes
  const findMatchingPaletteIndex = () => {
    console.log("colors", colors);
    return backgroundPalettes.findIndex((bgColor, index) => {
      return (
        bgColor === colors.mainBackground &&
        buttonPalettes[index] === colors.buttonsBackground
      );
    });
  };

  const backgroundPalettes = [
    "#fff",
    "#f0f7ee",
    "#c5c5c5",
    "#3f3f37",
    "#393d3f",
    "#080708",
    "#f6d8af",
    "#b7ad99",
    "#c5afa4",
    "#002a32",
    "#ef798a",
    "#785964",
    "#2e3532",
    "#463f3a",
    "#595959",
    "#0d1321",
    "#cecccc",
    "#ff5e5b",
    "#db5461",
    "#0a2342",
    "#a49e8d",
    "#ada8b6",
    "#32292f",
    "#c1c1c1",
    "#70d6ff",
    "#eaebed",
    "#080705",
    "#ebbab9",
    "#05a8aa",
    "#8c271e",
  ];
  const buttonPalettes = [
    "#000",
    "#c4d7f2",
    "#4c5b61",
    "#d6d6b1",
    "#fdfdff",
    "#3772ff",
    "#2e4057",
    "#ff4365",
    "#cc7e85",
    "#c4a29e",
    "#f7a9a8",
    "#82a7a6",
    "#8b2635",
    "#8a817c",
    "#8a817c",
    "#ffeddf",
    "#9d6381",
    "#d8d8d8",
    "#ffd9ce",
    "#2ca58d",
    "#504136",
    "#ffeedb",
    "#575366",
    "#2c4251",
    "#ff70a6",
    "#006989",
    "#40434e",
    "#c9c5ba",
    "#b8d5b8",
    "#aba194",
  ];
  const [selectedPaletteIndex, setSelectedPaletteIndex] = useState(
    findMatchingPaletteIndex
  );

  useEffect(() => {
    setSelectedPaletteIndex(findMatchingPaletteIndex);
  }, [colors]);

  const handlePaletteChange = (bgColor, btnColor, index) => {
    dispatch(
      setColors({ mainBackground: bgColor, buttonsBackground: btnColor })
    );
    setSelectedPaletteIndex(index); // Update the selected index
  };
  const isSelected = (index) => {
    return selectedPaletteIndex === index;
  };
  return (
    <>
      <Grid container spacing={2} sx={{}}>
        {backgroundPalettes.map((bgColor, index) => (
          <Grid item xs={6} key={index}>
            <Box
              sx={{
                margin: "10px",
                width: "60px",
                height: "40px",
                cursor: "pointer",
                background: `linear-gradient(to right, ${bgColor} 50%, ${buttonPalettes[index]} 50%)`,
                border: isSelected(index) ? "2px solid gold" : "none",
                "&:hover": {
                  borderColor: "gold",
                },
                borderRadius: "8px",
                marginLeft: tex == "ar" ? "auto" : "1",
              }}
              onClick={() =>
                handlePaletteChange(bgColor, buttonPalettes[index], index)
              }
            ></Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ColorPicker;
