import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  theme: -1,
  colors: {
    headerBackground: "rgb(240, 151, 145)",
    buttonsBackground: "#000",
    mainBackground: "#fff",
    featuredContentBackground: "#086564",
  },
  profileImage: "",
  coverImage: "",
  isPersonal:"",
  basic: {
    firstName: "",
    lastName: "",
    jobTitle: "",
    businessName: "",
    location: "",
    bio: "",
    phoneNumber: "",
    email: "",
    phoneNumSecondary: "",
  },
  otherLinks: [],
  socialMedia: [],

  contacts: [],

  contactsCustom: [],

  sections: [],
 id:null,
};
const theme=''
const FormSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setID: (state, action) => {
      state.id = action?.payload?.id;
    },
    setTheme: (state, action) => {
      state.theme = action?.payload?.theme;
    },
    
    setColors: (state, action) => {
      state.colors = { ...state.colors, ...action.payload };
    },
    setProfileImage: (state, action) => {
      state.profileImage = action.payload.image;
    },
    setIsPersonal: (state, action) => {
      state.isPersonal = action.payload.isPersonal;
    },
    setCoverImage: (state, action) => {
      state.coverImage = action.payload.coverImage;
    },
    setBasicInfo: (state, action) => {
      state.basic = action.payload.basic;
    },
    setFirstName: (state, action) => {
      state.basic.firstName = action?.payload?.basic?.firstName;
    },
    setLastName: (state, action) => {
      state.basic.lastName = action?.payload?.basic?.lastName;
    },
    setBasicInfoasync: (state, action) => {
      state.basic = { ...action.payload.basic };
    },
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload.phoneNumber;
    },
    setPrimarySMLinks: (state, action) => {
      state.socialMediaLinks = action.payload.socialMediaLinks;
    },
    setOtherLinksRedux: (state, action) => {
      state.otherLinks = [...state.otherLinks, ...action.payload.otherLinks];
    },
    filterOtherLinks: (state, action) => {
      const newArray = state.otherLinks.filter(
        (item, i) => i !== action.payload.i
      );
      state.otherLinks = newArray;
    },
    setImagesRedux: (state, action) => {
      state.images = [...state.images, ...action.payload.images];
    },
    setPdfsRedux: (state, action) => {
      state.pdfs = [...action.payload.pdfs];
    },

    setContacts: (state, action) => {
      const { type, idInthback, value, logo } = action.payload;

      const existingContactIndex = state.contacts.findIndex(
        (contact) => contact.type === type
      );

      if (existingContactIndex !== -1) {
        // Update existing contact
        state.contacts[existingContactIndex] = {
          ...state.contacts[existingContactIndex],
          idInthback:
            idInthback !== undefined
              ? idInthback
              : state.contacts[existingContactIndex].idInthback,
          value:
            value !== undefined
              ? value
              : state.contacts[existingContactIndex].value,
          logo: logo || state.contacts[existingContactIndex].logo,
        };
      } else {
        // Add new contact
        state.contacts.push({
          type,
          idInthback: idInthback !== undefined ? idInthback : null,
          value: value !== undefined ? value : "",
          logo,
        });
      }
    },

    setCustomContacts: (state, action) => {
      const existingIndex = state.contactsCustom.findIndex(
        (contact) => contact.id === action.payload.id
      );

      if (existingIndex === -1) {
        state.contactsCustom = [...state.contactsCustom, action.payload];
      } else {
        // Update existing contact
        state.contactsCustom[existingIndex] = action.payload;
      }
    },

    handleCustomChangeContacts: (state, action) => {
      const { index, field, value } = action.payload;
      const updatedInputs = [...state.contactsCustom];
      updatedInputs[index] = {
        ...updatedInputs[index],
        [field]: value,
      };

      return {
        ...state,
        contactsCustom: updatedInputs,
      };
    },

    handleCustomImageChangeContacts: (state, action) => {
      const updatedInputs = [...state.contactsCustom];

      updatedInputs[action.payload.index].image = action.payload.reader.result;
      state.contactsCustom = updatedInputs;
    },

    setSecondaryActions: (state, action) => {
      const socialMedia = state.socialMedia.find(
        (item) => item.type === action.payload.type
      );
      if (socialMedia) socialMedia.value = action.payload.value;
      else state.socialMedia = [...state.socialMedia, action.payload];
    },

    setTitleSections: (state, action) => {
      const updatedSections = [...state.sections];
      updatedSections[action.payload.sectionIndex].sectionName =
        action.payload.value;
    },
    setSections: (state, action) => {
      state.sections = action.payload;
    },
    setReduxSections: (state, action) => {
      state.sections = [...state.sections, action.payload];
    },

    reorderSections: (state, action) => {
      const { draggedIndex, targetIndex } = action.payload;
      const result = Array.from(state.sections);
      const [removed] = result.splice(draggedIndex, 1);
      result.splice(targetIndex, 0, removed);

      state.sections = result;
    },

    removeSection: (state, action) => {
      const updatedSections = state.sections.filter(
        (_, index) => index !== action.payload
      );
      state.sections = updatedSections;
    },

    addContentToSection: (state, action) => {
      const { sectionIndex, contentType } = action.payload;
      const updatedSections = [...state.sections];

      // Ensure that the content array is initialized
      if (!updatedSections[sectionIndex].content) {
        updatedSections[sectionIndex].content = [];
      }

      // Add the content to the specified section
      updatedSections[sectionIndex].content.push({
        type: contentType,
        value: action.payload.media,
      });

      state.sections = updatedSections;
    },

    removeContentFromSection: (state, action) => {
      const { sectionIndex, contentIndex } = action.payload;
      const updatedSections = [...state.sections];
      updatedSections[sectionIndex].content = updatedSections[
        sectionIndex
      ].content.filter((_, index) => index !== contentIndex);
      state.sections = updatedSections;
    },
    removeCustomContacts: (state, action) => {
      const index = action.payload;
      state.contactsCustom.splice(index, 1);
    },
    removeContacts: (state, action) => {
      const typeToRemove = action.payload.type;

      const index = state.contacts.findIndex(
        (contact) => contact.type === typeToRemove
      );

      if (index !== -1) {
        state.contacts.splice(index, 1);
      }
    },

    setContactsOrder: (state, action) => {
      const { newOrder } = action.payload;
      state.contacts = state.contacts
        .filter((contact) => newOrder.includes(contact.type))
        .sort((a, b) => newOrder.indexOf(a.type) - newOrder.indexOf(b.type));
    },

    setCustomContactsOrder: (state, action) => {
      const { newOrder } = action.payload;
      state.contactsCustom = newOrder;
    },
    resetState: (state) => {
      return initialState;
    },
    setPdfName: (state, action) => {
      const { sectionIndex, pdfName } = action.payload;
      return {
        ...state,
        sections: state.sections.map((section, index) =>
          index === sectionIndex ? { ...section, pdfName } : section
        ),
      };
    },
  },
});
export const {
  setID,
  setTheme,
  setColors,
  setProfileImage,
  setBasicInfo,
  setContacts,
  setPhoneNumber,
  setPrimarySMLinks,
  setIsPersonal,
  setOtherLinksRedux,
  setImagesRedux,
  setSecondaryActions,
  filterOtherLinks,
  setPdfsRedux,
  setCustomContacts,
  setCoverImage,
  setReduxSections,
  handleCustomChangeContacts,
  handleCustomImageChangeContacts,
  setTitleSections,
  setSections,
  reorderSections,
  removeSection,
  addContentToSection,
  removeContentFromSection,
  removeCustomContacts,
  removeContacts,
  setContactsOrder,
  setCustomContactsOrder,
  setPdfName,
  setBasicInfoasync,
  resetState,
  setFirstName,
  setLastName
} = FormSlice.actions;

export default FormSlice;
