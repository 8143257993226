import { Box, Button, Grid, Typography, Stack, TextField } from "@mui/material";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { setBasicInfo ,setFirstName,setIsPersonal, setLastName} from "../../Redux/Slices/FormSlice";
import TemplatePreview from "../../Components/TemplatePreview";
import { MuiTelInput } from "mui-tel-input";
import { debounce } from "lodash";
import UploadPhoto from "../../Components/UploadPhoto";
import { useTranslation } from "react-i18next";

const Step2 = ({ formikRef }) => {
  const { t } = useTranslation();
  const tex = localStorage.getItem("lann");
  const form = useSelector((state) => state.form); 
  const dispatch = useDispatch();
  const [showPreview, setShowPreview] = useState(false);
  const replaceNullWithEmptyString = (values) => {
    return Object.fromEntries(
      Object.entries(values).filter(
        ([key, value]) => value !== null && value !== "null"
      )
    );
  };

  const debouncedDispatch = debounce((values) => {
    dispatch(setBasicInfo({ basic: values }));
  }, 500);
  const handleChange = (values) => {
    debouncedDispatch(values);
  };
  useEffect(() => {
    // Set the default prefix if phoneNumber is empty
    if (
      !formikRef.current.values.phoneNumber ||
      formikRef.current.values.phoneNumber == "0000000"
    ) {
      formikRef.current.setFieldValue("phoneNumber", "+966");
    }
  }, [formikRef]);
  const [activeOption, setActiveOption] = useState(1);
useEffect(()=>{
  if(form.isPersonal=='true'){
    setActiveOption(1)
  }
  else if(form.isPersonal=='false'){
    setActiveOption(2)

  }
},[form.isPersonal])
  const handleClick = (option) => {
    setActiveOption(option);
  };

  const getValidationSchema = () => {
    let schemaFields = {
      businessName: Yup.string().max(100, "Must be under 100 characters"),
      location: Yup.string().max(100, "Must be under 100 characters"),
      phoneNumber: Yup.number().required("Please complete the data"),
      email: Yup.string().email("Invalid email").required("Please complete the data"),
    };

    if (form.isPersonal === 'true'||form.isPersonal === '') {
      schemaFields = {
        ...schemaFields,
        firstName: Yup.string().required("Please complete the data").max(100, "Must be under 100 characters"),
        lastName: Yup.string().required("Please complete the data").max(100, "Must be under 100 characters"),
      };
    } else if (form.isPersonal === 'false') {
      schemaFields.businessName = schemaFields.businessName.required("Please complete the data");
    }

    return Yup.object(schemaFields);
  };

  const handleSubmit = (values) => {};

  return (
    <Box>
      <Box
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          width: "100%",
          height: "100%",
          zIndex: "-100",
          background: "rgb(38, 38, 38)",
        }}
      ></Box>
      <div className=" w-screen flerx justify-center">
      <div className="flex border border-gray-300 rounded-md overflow-hidden mb-2">
      <div
        className={`flex-1 text-center py-2 cursor-pointer ${activeOption === 1 ? 'bg-[#e5cd79] text-white' : 'bg-gray-300 text-black'}`}
        onClick={() => {handleClick(1);dispatch(setIsPersonal({isPersonal:'true'}));      }}
      >
        
        {t("Personal")}

      </div>
      <div
        className={`flex-1 text-center py-2 cursor-pointer ${activeOption === 2 ? 'bg-[#e5cd79] text-white' : 'bg-gray-300 text-black'}`}
        onClick={() => {handleClick(2);dispatch(setIsPersonal({isPersonal:'false'}));    dispatch(setFirstName({ firstName: null })); dispatch(setLastName({ lastName: null }));   }}
      >
      {t("Company")}
      </div>
    </div>
    </div>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" ,  marginBottom: "3rem"}}></div>
      {/**form start */}
      <div className="flex justify-center">
      
        <Grid spacing={2} container justifyContent={"center"}>
          <Grid
            item
            md={6}
            xs={12}
            style={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: tex === "ar" ? "end" : "start",
            }}
          >
          
            <Box m="1rem" style={{ marginTop: "-2rem" }}>
              <Formik
                innerRef={formikRef}
                initialValues={replaceNullWithEmptyString(form.basic)}
                enableReinitialize={true}
                validationSchema={getValidationSchema()}
                onSubmit={handleSubmit}
              >
                {(formik) => {
                  return (
                    <form
                      style={{
                        margin: "0 10",
                        width: "100%",
                        marginRight: tex === "ar" ? "0" : "0.3rem",
                      }} // Adjust the width as needed
                      onSubmit={formik.handleSubmit}
                    >
                  
                      <Box
                        sx={{ padding: "1rem 0.5rem", marginBottom: "1rem" }}
                      >
                

                        <Typography
                          variant="h1"
                          sx={{
                            color: "#e5cd79",
                            fontFamily: "Robote_bold",
                            fontSize: {
                              xs: "1.4rem", // Adjust font size for smaller screens
                              md: "1.9rem", // Default font size for medium and larger screens
                            },
                            marginLeft: tex === "en" ? "-20px" : "8rem",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {t("PersonalـData")}
                        </Typography>
                      </Box>
                      <div className="w-[100%]">
                        <Stack
                          gap={"1rem"}
                          className={`w-[100%] ${
                            tex === "ar" ? "" : "ml-0"
                          }`}
                        >

                        {activeOption ==2?
                          <TextField
                            size="small"
                            label={t("BusinessـName")}
                            id="businessName"
                            type="text"
                            error={
                              formik.touched.businessName &&
                              formik.errors.businessName
                            }
                            helperText={
                              formik.touched.businessName &&
                              formik.errors.businessName &&
                              formik.errors.businessName
                            }
                            {...formik.getFieldProps("businessName")}
                            onChange={(e) => {
                              formik.handleChange(e); // Call Formik's handleChange
                              handleChange({
                                ...formik.values,
                                businessName: e.target.value,
                              });
                            }}
                            className="bg-text"
                            style={{
                              width: "100%",
                              marginLeft: tex === "en" ? "-0.6rem" : "0rem",
                            }}
                          />
                      :""}
                        {activeOption ==1?
                          <TextField
                            size="small"
                            label={t("FirstـName")}
                            id="firstName"
                            type="text"
                            error={
                              formik.touched.firstName &&
                              Boolean(formik.errors.firstName)
                            }
                            helperText={
                              formik.touched.firstName &&
                              formik.errors.firstName &&
                              formik.errors.firstName
                            }
                            {...formik.getFieldProps("firstName")}
                            className="bg-text"
                            onChange={(e) => {
                              formik.handleChange(e); // Call Formik's handleChange
                              handleChange({
                                ...formik.values,
                                firstName: e.target.value,
                              });
                            }}
                            style={{
                              width: "100%",
                              marginLeft: tex === "en" ? "-0.6rem" : "0rem",
                            }}
                          />
                        :""}
                        {activeOption ==1?
                          <TextField
                            size="small"
                            label={t("LastـName")}
                            id="lastName"
                            type="text"
                            error={
                              formik.touched.lastName && formik.errors.lastName
                            }
                            helperText={
                              formik.touched.lastName &&
                              formik.errors.lastName &&
                              formik.errors.lastName
                            }
                            {...formik.getFieldProps("lastName")}
                            onChange={(e) => {
                              formik.handleChange(e); // Call Formik's handleChange
                              handleChange({
                                ...formik.values,
                                lastName: e.target.value,
                              });
                            }}
                            className="bg-text"
                            style={{
                              width: "100%",
                              marginLeft: tex === "en" ? "-0.6rem" : "0rem",
                            }}
                          />
                          :""}
                          <MuiTelInput
                            size="small"
                            label={t("PhoneـNumber")}
                            error={
                              formik.touched.phoneNumber &&
                              Boolean(formik.errors.phoneNumber)
                            }
                            helperText={
                              formik.touched.phoneNumber &&
                              formik.errors.phoneNumber
                            }
                            value={formik.values.phoneNumber}
                            onChange={(value) => {
                              formik.setFieldValue("phoneNumber", value); // Set Formik's field value directly
                              handleChange({
                                ...formik.values,
                                phoneNumber: value,
                              });
                            }}
                            className="bg-text"
                            style={{
                              width: "100%",
                              marginLeft: tex === "en" ? "-0.6rem" : "0rem",
                            }}
                          />

                          <TextField
                            size="small"
                            label={t("Email")}
                            id="email"
                            type="text"
                            error={formik.touched.email && formik.errors.email}
                            helperText={
                              formik.touched.email &&
                              formik.errors.email &&
                              formik.errors.email
                            }
                            {...formik.getFieldProps("email")}
                            onChange={(e) => {
                              formik.handleChange(e); // Call Formik's handleChange
                              handleChange({
                                ...formik.values,
                                email: e.target.value,
                              });
                            }}
                            className="bg-text"
                            style={{
                              width: "100%",
                              marginLeft: tex === "en" ? "-0.6rem" : "0rem",
                            }}
                          />
                          <TextField
                            size="small"
                            label={t("JobـTitle")}
                            id="jobTitle"
                            type="text"
                            error={
                              formik.touched.jobTitle && formik.errors.jobTitle
                            }
                            helperText={
                              formik.touched.jobTitle &&
                              formik.errors.jobTitle &&
                              formik.errors.jobTitle
                            }
                            {...formik.getFieldProps("jobTitle")}
                            onChange={(e) => {
                              formik.handleChange(e); // Call Formik's handleChange
                              handleChange({
                                ...formik.values,
                                jobTitle: e.target.value,
                              });
                            }}
                            className="bg-text"
                            style={{
                              width: "100%",
                              marginLeft: tex === "en" ? "-0.6rem" : "0rem",
                            }}
                          />
                          {activeOption ==1?
                          <TextField
                            size="small"
                            label={t("BusinessـName")}
                            id="businessName"
                            type="text"
                            error={
                              formik.touched.businessName &&
                              formik.errors.businessName
                            }
                            helperText={
                              formik.touched.businessName &&
                              formik.errors.businessName &&
                              formik.errors.businessName
                            }
                            {...formik.getFieldProps("businessName")}
                            onChange={(e) => {
                              formik.handleChange(e); // Call Formik's handleChange
                              handleChange({
                                ...formik.values,
                                businessName: e.target.value,
                              });
                            }}
                            className="bg-text"
                            style={{
                              width: "100%",
                              marginLeft: tex === "en" ? "-0.6rem" : "0rem",
                            }}
                          />
                          :''}
                          <TextField
                            size="small"
                            label={t("Location")}
                            id="location"
                            type="text"
                            error={
                              formik.touched.location && formik.errors.location
                            }
                            helperText={
                              formik.touched.location &&
                              formik.errors.location &&
                              formik.errors.location
                            }
                            {...formik.getFieldProps("location")}
                            onChange={(e) => {
                              formik.handleChange(e); // Call Formik's handleChange
                              handleChange({
                                ...formik.values,
                                location: e.target.value,
                              });
                            }}
                            className="bg-text"
                            style={{
                              width: "100%",
                              marginLeft: tex === "en" ? "-0.6rem" : "0rem",
                            }}
                          />
                          <TextField
                            size="small"
                            label={t("Bio")}
                            multiline
                            rows={4}
                            id="bio"
                            type="text"
                            error={formik.touched.bio && formik.errors.bio}
                            helperText={
                              formik.touched.bio &&
                              formik.errors.bio &&
                              formik.errors.bio
                            }
                            {...formik.getFieldProps("bio")}
                            onChange={(e) => {
                              formik.handleChange(e); // Call Formik's handleChange
                              handleChange({
                                ...formik.values,
                                bio: e.target.value,
                              });
                            }}
                            className="bg-text"
                            style={{
                              width: "100%",
                              marginLeft: tex === "en" ? "-0.6rem" : "0rem",
                            }}
                          />
                        </Stack>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </Box>


            
            <Box
              sx={{
                padding: "1rem 0.5rem",
                marginRight: {
                  xs: tex === "ar" ? "0rem" : "0", // Smaller font size for extra-small screens
                  sm: tex === "ar" ? "0rem" : "0", // Medium font size for small screens
                  md: tex === "ar" ? "0rem" : "0", // Default font size for medium screens
                  lg: tex === "ar" ? "0rem" : "0", // Larger font size for large screens
                  xl: tex === "ar" ? "0rem" : "0", // Extra-large font size for extra-large screens
                },
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  color: "#e5cd79",
                  fontFamily: "Robote_bold",
                  fontSize: {
                    xs: "1.4rem", // Adjust font size for smaller screens
                    md: "1.9rem", // Default font size for medium and larger screens
                  },
                }}
                style={{
                  marginLeft: tex === "en" ? "0rem" : "-3.5rem",
                }}
              >
                {t("ProfileـPhoto")}
              </Typography>
              <div
                style={{
                  marginLeft: tex === "en" ? "0rem" : "-1.0rem",
                  marginRight: "5px",
                }}
              >
                <UploadPhoto type="profile" />
              </div>
            </Box>



            <Box
              sx={{
                padding: "1rem 0.5rem",
                marginRight: {
                  xs: tex === "ar" ? "0rem" : "0", // Smaller font size for extra-small screens
                  sm: tex === "ar" ? "0rem" : "0", // Medium font size for small screens
                  md: tex === "ar" ? "0rem" : "0", // Default font size for medium screens
                  lg: tex === "ar" ? "0rem" : "0", // Larger font size for large screens
                  xl: tex === "ar" ? "0rem" : "0", // Extra-large font size for extra-large screens
                },
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  color: "#e5cd79",
                  fontFamily: "Robote_bold",
                  fontSize: {
                    xs: "1.4rem", // Adjust font size for smaller screens
                    md: "1.9rem", // Default font size for medium and larger screens
                  },
                  marginLeft: {
                    xs: tex === "en" ? "-5px" : "0rem",
                    md: tex === "en" ? "-5px" : "0rem",
                  },
                }}
              >
                {t("CoverـPhoto")}
              </Typography>
              <Box
                style={{
                  marginLeft: tex === "en" ? "-0.3rem" : "2.3rem",
                }}
              >
                <UploadPhoto type="cover" />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sx={{
              display: {
                xs: "block",
                md: "block",
              },
            }}
          >
            <Box
              sx={{
                position: { xs: "fixed", md: "relative" },
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                zIndex: "10000",
                width: "325px",
                backgroundColor: { xs: "#000000e8", md: "transparent" },
                overflowY: "auto",
                transition: "0.3s ease-in",
                opacity: { md: "1", sx: showPreview ? "1" : "0" },
              }}
            >
              {showPreview == true ? (
                <Box
                  sx={{
                    background: "#000",
                    width: "100%",
                    height: "100%",
                    borderRadius: "20px",
                  }}
                >
                  <Button
                    sx={{ display: "block", margin: "0 auto", zIndex: "2" }}
                    variant="contained"
                    onClick={() => {
                      setShowPreview(false);
                    }}
                    size="small"
                  >
                    {t("Closeـpreview")}
                  </Button>
                  <Box sx={{ zIndex: "100" }}>
                    <TemplatePreview />       
                  </Box>                      
                </Box>
              ) : (
                ""
              )}
              <Box
                sx={{
                  display: { xs: "none", md: "block" }, // Hidden on small screens, visible on medium and larger screens
                  opacity: { sx: "0", md: "1" },
                }}
              >
                <TemplatePreview />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div> 
    </Box>
  );
};

export default Step2;

