import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import axios from "axios";
export default function Reset() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    // Add additional password validations here if needed

    try {
      // Replace with actual data required by your API
      const payload = {
        email: localStorage.getItem("email"),
        password: newPassword,
      };

      const response = await axios.post(
        "https://api.dev4.gomaplus.tech/api/change_password",
        payload
      );

      // Handle the response here
      console.log("Response from API:", response.data);
      console.log("Password has been reset to:", newPassword);
      navigate("/");
      // Reset state and possibly redirect user or show success message
      setNewPassword("");
      setConfirmPassword("");
      setError("");
      // Redirect or inform user of successful password reset
    } catch (error) {
      // Handle errors here, such as displaying a message to the user
      console.error("Error during password reset:", error);
      setError("Failed to reset password. Please try again.");
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#262626",
        minHeight: "80vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Robote_bold",
      }}
    >
      <Box
        sx={{
          width: {
            xs: "90%", // Smaller font size for extra-small screens
            sm: "80%", // Medium font size for small screens
            md: "70%", // Default font size for medium screens
            lg: "60%", // Larger font size for large screens
            xl: "50%", // Extra-large font size for extra-large screens
          },
          padding: "24px",
          backgroundColor: "rgb(142 120 92)",
          borderRadius: "8px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          fontFamily: "Robote_bold",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            marginBottom: "8px",
            color: "#262626",
            fontFamily: "Robote_bold",
            fontWeight: "600",
          }}
        >
          Change Password
        </Typography>
        <form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            fontFamily: "Robote_bold",
          }}
        >
          <TextField
            type={showPassword ? "text" : "password"}
            label="New Password"
            variant="outlined"
            value={newPassword}
            onChange={handlePasswordChange}
            fullWidth
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleTogglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            type="password"
            label="Confirm Password"
            variant="outlined"
            value={confirmPassword}
            sx={{ fontFamily: "Robote_bold" }}
            onChange={handleConfirmPasswordChange}
            fullWidth
            required
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ marginTop: "16px", fontFamily: "Robote_bold" }}
          >
            Reset Password
          </Button>
          {error && <Typography color="error">{error}</Typography>}
        </form>
      </Box>
    </Box>
  );
}
