import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ThemeColor } from "../data/Theme";
import { useDispatch, useSelector } from "react-redux";
import { setCoverImage, setProfileImage } from "../Redux/Slices/FormSlice";
import ImageIcon from "@mui/icons-material/Image";
import "cropperjs/dist/cropper.css";
import { Cropper } from "react-cropper";
import AddIcon from "@mui/icons-material/Add";
// import { IoCloseSharp } from "react-icons/io5";
import { FaRegTrashAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const UploadPhoto = ({ type }) => {
  const dispatch = useDispatch();
  const { profileImage, coverImage } = useSelector((state) => state.form);
  const theme = useTheme();
  const { t } = useTranslation();
  const imageRef = useRef();
  const [imagePath, setImagePath] = useState();
  const [coverPath, setCoverPath] = useState();
  const [openCropper, setOpenCropper] = useState(false);
  const cropperRef = useRef();

  const handleImageChange = (e) => {
    if (type === "profile")
      dispatch(
        setProfileImage({ image: URL.createObjectURL(e.target.files[0]) })
      );
    else
      dispatch(
        setCoverImage({ coverImage: URL.createObjectURL(e.target.files[0]) })
      );

    setOpenCropper(true);
  };

  const [imagedata, setImagedata] = useState(null);

  //console.log(profileImage);

  useEffect(() => {
    if (profileImage) setImagePath(profileImage);
    else setImagePath("");
  }, [profileImage, setImagePath, coverImage]);

  useEffect(() => {
    if (coverImage) setCoverPath(coverImage);
    else setCoverPath("");
  }, [coverImage]);

  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;
    //console.log(cropper.getCroppedCanvas().toDataURL());
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      if (type === "profile")
        dispatch(
          setProfileImage({
            image: cropperRef.current?.cropper.getCroppedCanvas().toDataURL(),
          })
        );
      else
        dispatch(
          setCoverImage({
            coverImage: cropperRef.current?.cropper
              .getCroppedCanvas()
              .toDataURL(),
          })
        );
    }

    setOpenCropper(false);
  };

  return (
    <Box py={"1rem"}>
      <Box>
        <Stack
          alignItems={"center"}
          direction={"row"}
          gap={"1rem"}
          marginLeft={"2rem"}
        >
          <Box
            sx={{
              backgroundColor: theme.palette.lighter.main,
              backgroundImage: `url(${
                type === "profile" ? imagePath : coverPath
              })`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              borderRadius: "10px",
              width: "70px",
              height: "70px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#262626",
              cursor: "pointer",
            }}
            onClick={() => {
              imageRef.current.click();
            }}
          >
            <AddIcon
              style={{
                color: "#eee",
                fontSize: "2rem",
                border: "1px solid",
                borderRadius: "25%",
              }}
            />
          </Box>
          {type == "profile" ? (
            <>
              {imagePath ? (
                <FaRegTrashAlt
                  style={{
                    color: "red",
                    fontSize: "1.1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (type === "profile") {
                      //console.log(profileImage);
                      dispatch(
                        setProfileImage({
                          image: null,
                        })
                      );
                    } else {
                      dispatch(
                        setCoverImage({
                          coverImage: null,
                        })
                      );
                    }
                  }}
                />
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              {coverPath ? (
                <FaRegTrashAlt
                  style={{
                    color: "red",
                    fontSize: "1.1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (type === "profile") {
                      //console.log(profileImage);
                      dispatch(
                        setProfileImage({
                          image: null,
                        })
                      );
                    } else {
                      dispatch(
                        setCoverImage({
                          coverImage: null,
                        })
                      );
                    }
                  }}
                />
              ) : (
                ""
              )}
            </>
          )}
        </Stack>
      </Box>
      <input
        onChange={handleImageChange}
        type="file"
        accept="image/*"
        ref={imageRef}
        hidden
      />
      {openCropper && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: "rgba(0, 0, 0, 0.73)",
            zIndex: 10000,
            padding: { xs: "2rem", md: "4rem" }, // Responsive padding
          }}
        >
          <Cropper
            ref={cropperRef}
            src={type === "profile" ? imagePath : coverPath}
            style={{
              maxHeight: "40vh", // Limit height to 80% of viewport height
              width: "100%", // Full width of container
              maxWidth: "300px",
            }}
            aspectRatio={type === "profile" ? 1 : 2} // Maintain aspect ratio
            initialAspectRatio={1}
            viewMode={1}
            background={false}
            responsive={true}
            autoCropArea={1}
            guides={true}
            onCrop={() => {}}
          />

          <Button
            sx={{ mt: 1, width: "fit-content" }}
            variant="contained"
            onClick={getCropData}
          >
            {t("crop")}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default UploadPhoto;
