import { Box, Typography, Stack, Tooltip, Grid, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ThemeColor } from "../../data/Theme";

import LinkIcon from "@mui/icons-material/Link";
import { useSelector } from "react-redux";
import DraggableList from "../../Components/DraggableList";
import TemplatePreview from "../../Components/TemplatePreview";
import DraggableFeatured from "../../Components/DraggableFeatured";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Step3 = () => {
  const [showMyLinksPopup, setShowMyLinksPopup] = useState(false);
  const [primaryLinksFromApi, setPrimaryLinksFromApi] = useState([]);
  const { otherLinks } = useSelector((state) => state.form);
  const primaryLinksApi = "https://api.dev4.gomaplus.tech/api/P_link";
  const { t } = useTranslation();
  const tex = localStorage.getItem("lann");
  
  const fetchData = async () => {
    try {
      const response = await axios.get(primaryLinksApi, {
        headers: {
          // Include any required headers here
          // Example:
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          ContentType: "application/json",
        },
      });
      setPrimaryLinksFromApi(response.data.data);
      console.log("Response:", response.data.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [showPreview, setShowPreview] = useState(false);

  return (
    <Box>
      {showPreview && (
        <Box
          sx={{
            background: "#000",
            position: "fixed",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
            width: "100vw",
            height: "100vh",
            zIndex: "100",
          }}
        ></Box>
      )}
      <Box
        sx={{
          padding: "0.5rem",
          borderRadius: "10px",
          width: "100%",
          position: "sticky",
          margin: "0 auto",
          zIndex: "100",
          display: { xs: "flex", md: "none" },
          top: "0",
          right: "0",
          alignContent: "right",
          alignItems: "right",
          justifyContent: "center",
          marginRight: "1rem",
          marginTop: "16px",
        }}
      ></Box>
      <Box
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          width: "100%",
          height: "100%",
          zIndex: "-100",
          background: "rgb(38, 38, 38)",
        }}
      ></Box>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Box>
            <Stack
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stack direction={"row"} gap={"1rem"}>
                {otherLinks.length > 0 && (
                  <Tooltip title={"Show my Links"}>
                    <LinkIcon
                      sx={{
                        color: ThemeColor.title,
                        fontSize: "1.4rem",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setShowMyLinksPopup(!showMyLinksPopup);
                      }}
                    />
                  </Tooltip>
                )}
              </Stack>
            </Stack>
            <Box>
              <div>
                <Typography
                  variant="h1"
                  sx={{
                    color: "#e5cd79",
                    fontSize: "2rem", // Default font size for small screens
                    textAlign: tex === "ar" ? "end" : "start",
                    marginRight: "1rem",
                    fontFamily: "Robote_bold", 
                    "@media (min-width: 600px)": {
                      fontSize: "3rem",
                    },
                  }}
                >
                  {t("Links")}
                </Typography>
                <Box
                  sx={{
                    margin: "0.8rem 0",
                    marginRight: "1rem",
                    marginLeft: "-0.4rem",
                  }}
                >
                  <DraggableList
                    type="primary"
                    buttonNames={primaryLinksFromApi}
                  />
                </Box>
              </div>
              <div className="mt-10">
                <Typography
                  variant="h1"
                  sx={{
                    color: "#e5cd79",
                    fontSize: "2rem", // Default font size for small screens
                    textAlign: tex === "ar" ? "end" : "start",
                    marginRight: "1rem",
                    fontFamily: "Robote_bold", // Center the text
                    "@media (min-width: 600px)": {
                      fontSize: "3rem", // Increase font size for larger screens
                    },
                    marginTop: "10px",
                  }}
                >
                  {t("Media")}
                </Typography>
                <Box sx={{ margin: "0.8rem 0" }}>
                  <DraggableFeatured />
                </Box>
                {/** <DraggableList type="secondary" buttonNames={SecondaryActions} />
                 */}
              </div>
            </Box>
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box
            sx={{
              display: {
                xs: "block",

                md: "block",
              },
              position: { xs: "fixed", md: "relative" },
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              zIndex: "10000",
              width: "325px",

              backgroundColor: { xs: "#000000e8", md: "transparent" },
              overflowY: "auto",
              transition: "0.3s ease-in",
              opacity: { md: "1", sx: showPreview ? "1" : "0" },
            }}
          >
            {showPreview == true ? (
              <Box
                sx={{
                  background: "#000",
                  width: "100%",
                  height: "100%",
                  borderRadius: "20px",
                }}
              >
                <Button
                  sx={{ display: "block", margin: "0 auto", zIndex: "2" }}
                  variant="contained"
                  onClick={() => {
                    setShowPreview(false);
                  }}
                  size="small"
                >
                  {t("Closeـpreview")}
                </Button>
                <Box sx={{ zIndex: "100" }}>
                  <TemplatePreview />
                </Box>
              </Box>
            ) : (
              ""
            )}
            <Box
              sx={{
                display: { xs: "none", md: "block" },
                opacity: { sx: "0", md: "1" },
              }}
            >
              <TemplatePreview />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Step3;
