import React, { useEffect, useState } from "react";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  setColors,
  setTheme,
  setProfileImage,
  setBasicInfo,
  setContacts,
  setCustomContacts,
  setCoverImage,
  setSections,
  resetState,
} from "../../Redux/Slices/FormSlice";
import { useDispatch, useSelector } from "react-redux";
import loginImage from "../../assets/images/login.png";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const form = useSelector((state) => state.form);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  // Function to reset the form state
  const resetFormState = () => {
    dispatch(resetState());
  };

  useEffect(() => {
    // Call the reset function when the comp onent mounts
    resetFormState();
    // Clear local storage or any other initializations
    localStorage.clear();
  }, [dispatch]); // Add dispatch to the dependency array

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    localStorage.clear();
  }, []);
  const handleLogin = () => {
    setIsLoading(true);
    axios
      .post("https://api.dev4.gomaplus.tech/api/login", {
        username: email,
        password: password,
      })
      .then((response) => {
        const token = response.data.token;
        const profile = response.data.user.profile;
        localStorage.setItem("token", token);
        localStorage.setItem("profile", profile);

        console.log(response.data.user.profile);
        if (profile !== null) {
          return axios.get(
            `https://api.dev4.gomaplus.tech/api/profile/${profile}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } else {
          // If profile is null, skip further processing and navigate to the next page
          dispatch(resetState());
          navigate("/Steper");
          return null; // Return null to stop the promise chain
        }
      })

      .then((userResponse) => {
        const formData = userResponse.data.data;

        console.log("formData", formData);

        dispatch(
          setColors({
            buttonsBackground: formData?.buttonColor,
            mainBackground: formData?.bgColor,
          })
        );
        dispatch(setTheme(formData?.theme));

        dispatch(setProfileImage({ image: formData?.photo }));
        dispatch(setCoverImage({ coverImage: formData?.cover }));
        dispatch(
          setBasicInfo({
            basic: {
              firstName: formData?.firstName,
              lastName: formData?.lastName,
              jobTitle: formData?.jobTitle,
              businessName: formData?.businessName,
              location: formData?.location,
              bio: formData?.bio,
              phoneNumber: formData?.phoneNum,
              email: formData?.email,
              phoneNumSecondary: formData?.phoneNumSecondary,
            },
          })
        );

        formData?.primary_links?.forEach((contact) => {
          dispatch(
            setContacts({
              type: contact.name,
              value: contact.value,
              idInthback: contact.id, // Make sure this is correctly passed
              logo: contact.logo,
              // idInthback:contact.id
            })
          );
        });

        formData?.second_links?.forEach((customContact) => {
          dispatch(
            setCustomContacts({
              id: customContact.id,
              link: customContact.link,
              logo: customContact.logo,
              name_link: customContact.name_link,
            })
          );
        });

        const sectionsToAdd = formData?.section?.map((section) => ({
          id: section.id,
          sectionName: section.title,
          media: section.media,
          contentType: "video",
        }));

        if (sectionsToAdd) {
          dispatch(setSections(sectionsToAdd));
        }

        setIsLoading(false);
        console.log(form);
        navigate("/Steper");
      })
      .catch((err) => {
        console.error("Login failed:", err);
        setError("Login failed. Please check your credentials.");
        setIsLoading(false);
      });
  };

  return (
    <Stack
      minHeight={"100vh"}
      padding={"0 2rem"}
      alignItems={"center"}
      direction={{ md: "row", sm: "column-reverse", xs: "column-reverse" }}
      paddingBottom={"6rem"}
    >
      <Box ox sx={{ flex: "0.9", padding: { md: "1rem", sm: "0" } }}>
        <Box mb={"2rem"}>
          <Typography
            variant="h1"
            sx={{
              color: "white",
              textAlign: "center", // Center the text
              fontSize: {
                xs: "2rem", // Smaller font size for extra-small screens
                sm: "2.5rem", // Medium font size for small screens
                md: "3rem", // Default font size for medium screens
                lg: "3.5rem", // Larger font size for large screens
                xl: "5rem", // Extra-large font size for extra-large screens
              },
            }}
          >
            Digital Business Card
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Roboto, sans-serif", // Set Roboto as the font family

              color: "#fff",
              textAlign: "center", // Center the text
              fontSize: {
                xs: "0.7rem", // Smaller font size for extra-small screens
                sm: "0.9rem", // Medium font size for small screens
                md: "1rem", // Default font size for medium screens
                lg: "1.2rem", // Larger font size for large screens
                xl: "1.4rem", // Extra-large font size for extra-large screens
              },
            }}
          >
            Make Your Own Design And Share It With People
          </Typography>
        </Box>
        <Stack padding={{ lg: "0 3rem", md: "0" }} gap={"1rem"} mb={"2rem"}>
          <TextField
            label="User name / Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
          />
          <TextField
            label="Password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleTogglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {error && <Typography color="error">{error}</Typography>}
          <Button
            onClick={handleLogin}
            fullWidth
            variant="contained"
            sx={{
              "&:hover": { backgroundColor: theme.palette.primary.dark },
              background:
                isLoading || email == "" || password == ""
                  ? "gray"
                  : "linear-gradient(to right, rgba(229, 205, 121, 0.4), rgba(229, 205, 121, 0.7))",
              // ... your other styles
            }}
            style={{
              backdropFilter: "blur(8px)", // Glass effect
              border: "1px solid rgba(229, 205, 121, 0.5)", // Golden border
              boxShadow:
                "0 4px 8px rgba(0, 0, 0, 0.3), 0 6px 20px rgba(0, 0, 0, 0.3)", // Shadow for 3D effect

              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              padding: "0.3rem 0.5rem",
              transition: "transform 0.3s ease",
              display: "flex",
              justifyContent: "center",

              height: "2.7rem",
              zIndex: "20",
              marginTop: "0.5rem",

              marginLeft: "0rem",
              position: "relative",
            }}
            disabled={isLoading || email === "" || password === ""}
          >
            {isLoading ? (
              <CircularProgress
                size={24}
                style={{
                  color: "black",
                  position: "absolute", // To overlay the loader on the button
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px", // Half of the size to center it vertically
                  marginLeft: "-12px", // Half of the size to center it horizontally
                }}
              />
            ) : (
              <h4 style={{ color: "white" }}> Login </h4>
            )}
          </Button>
          <h4
            style={{ color: "#e5cd79", cursor: "pointer" }}
            onClick={() => {
              navigate("/Forgetpassword");
            }}
          >
            {" "}
            Reset my password{" "}
          </h4>
        </Stack>
      </Box>

      <Box
        sx={{
          flex: "1.1",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          [theme.breakpoints.down("sm")]: {
            marginTop: "2rem",
          },
        }}
      >
        <img
          src={loginImage}
          alt="Login"
          style={{ width: "90%", maxWidth: "400px" }}
        />
      </Box>
    </Stack>
  );
};

export default Login;
