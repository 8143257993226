import React, { useState } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";

export default function Forgetpassword() {
  const [newEmail, setNewEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const handleNewEmailChange = (event) => {
    setNewEmail(event.target.value);
  };

  const handleConfirmEmailChange = (event) => {
    setConfirmEmail(event.target.value);
  };
  const token = localStorage.getItem("token");

  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    if (!newEmail) {
      setError("Enter your email address");
      setIsLoading(false);
      return;
    }

    try {
      // Check if email exists
      const checkEmailResponse = await axios.get("https://api.dev4.gomaplus.tech/api/check_for_email", {
        params: {
          email: newEmail, // Assuming newEmail is a variable holding the email address to check
        }
      });
      
      // If email does not exist, set error
      if (!checkEmailResponse.data.exists) {
        setError("Email does not exist");
        setIsLoading(false);
        return;
      }

      // If email exists, proceed to create code
      const response = await axios.post(
        "https://api.dev4.gomaplus.tech/api/create_code",
        {
          email: newEmail,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      console.log("Response from API:", response.data);

      // Implement success logic
      console.log("Email has been reset to:", newEmail);
      localStorage.setItem("email", newEmail);
      localStorage.setItem("thepassowrdforgoten", "true");
      navigate("/resendOTP");

      // Reset state
      setNewEmail("");
      setConfirmEmail("");
      setError("");
      setIsLoading(false);
      // Redirect or inform user of successful email reset
    } catch (error) {
      // Handle errors here, such as displaying a message to the user
      console.error("Error in email reset:", error);
      setIsLoading(false);
      setError("Failed to reset email. Please try again.");
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#262626",
        minHeight: "80vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "50%",
          width: {
            xs: "90%", // Smaller font size for extra-small screens
            sm: "80%", // Medium font size for small screens
            md: "70%", // Default font size for medium screens
            lg: "60%", // Larger font size for large screens
            xl: "50%", // Extra-large font size for extra-large screens
          },
          padding: "24px",
          backgroundColor: "rgba(142, 120, 92, 0.2)", // Semi-transparent background
          backdropFilter: "blur(10px)", // Blur effect for the glass look
          borderRadius: "8px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          border: "1px solid rgba(255, 255, 255, 0.2)", // Optional: add a light border for a more defined edge
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            marginBottom: "8px",
            color: " rgb(229, 205, 121)",
            fontFamily: "Robote_bold",
          }}
        >
          {t("enter your email")}
        </Typography>
        <form
          onSubmit={handleSubmit}
          style={{ display: "flex", flexDirection: "column", gap: "16px" }}
        >
          <TextField
            type="email"
            label={t("email")}
            variant="outlined"
            value={newEmail}
            onChange={handleNewEmailChange}
            fullWidth
            required
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{
              marginTop: "16px",
              backgroundColor: !newEmail || isLoading ? "grey" : "", // Set background to grey if newEmail is empty or isLoading
              "&:hover": {
                backgroundColor: !newEmail || isLoading ? "grey" : "", // Keep grey on hover if newEmail is empty or isLoading
              },
            }}
            disabled={!newEmail || isLoading} // Disable button if newEmail is empty or isLoading
          >
            {isLoading ? (
              <CircularProgress
                size={24}
                style={{
                  color: "black",
                  position: "absolute", // To overlay the loader on the button
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px", // Half of the size to center it vertically
                  marginLeft: "-12px", // Half of the size to center it horizontally
                }}
              />
            ) : (
              <h4 style={{ color: "black", fontFamily: "Robote_bold" }}>
                send verification code
              </h4>
            )}
          </Button>
          {error && <Typography color="error">{error}</Typography>}
        </form>
        <Link style={{ marginTop: "50px", textDecoration: "none" }}></Link>
      </Box>
    </Box>
  );
}
