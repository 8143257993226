import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineLanguage } from "react-icons/md";

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  localStorage.setItem("lann", selectedLanguage);
  // const tex=localStorage.getItem('lann');
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
    toggleDropdown();
    console.log(`Language changed to: ${language}`);
  };
  const LANGUAGES = [
    { label: "En", code: "en" },
    { label: "ع", code: "ar" }, // Corrected the code for Arabic
  ];
  return (
    <div
      style={{
        background: "#262626",
        color: "black",
        padding: "0.4rem 0.3rem",
        borderRadius: "5px",
        textTransform: "capitalize",
        fontWeight: "bold",
        outline: "none",
        border: "1px solid #e5cd79",
        cursor: "pointer",
        fontSize: "13px",
        alignItems: "center",
        width: "54px",
        position: "relative", // To position the dropdown correctly
      }}
      onClick={toggleDropdown}
    >
      <div className="flex justify-between">
        <MdOutlineLanguage color="#e5cd79" size={20} />
        <h2 className="ml-2 text-white">
          {selectedLanguage == "en" ? "En" : "ع"}
        </h2>
      </div>
      {isOpen && (
        <ul
          style={{
            listStyleType: "none",
            padding: "0.5rem 0", // Adding vertical padding for breathing space
            margin: 0,
            position: "absolute",
            top: "100%",
            left: 0,
            zIndex: 1,
            border: "1px solid #e5cd79",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
            borderRadius: "5px",
            width: "auto", // Auto width for flexibility
            minWidth: "15px", // Minimum width for better readability
            overflow: "hidden", // Hides any overflowing content
          }}
        >
          {LANGUAGES.filter(({ code }) => code !== selectedLanguage).map(
            ({ code, label }) => (
              <li
                key={code}
                style={{
                  backgroundColor: "#262626",
                  cursor: "pointer",

                  textAlign: "center", // Ensuring text alignment
                  whiteSpace: "nowrap", // Prevents text wrapping
                  overflow: "hidden", // Hides overflowing text
                  textOverflow: "ellipsis", // Adds ellipsis for overflowing text
                  ":hover": {
                    // Hover effect
                    backgroundColor: "#f5f5f5",
                  },
                  borderRadius: "3px",
                  color: "white",
                  width: "52px",
                  height: "100%",
                }}
                onClick={() => changeLanguage(code)}
              >
                {label}
              </li>
            )
          )}
        </ul>
      )}
    </div>
  );
}

export default LanguageSwitcher;
