import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Template2 from "../Pages/Templates/Template2/Template2";
import Template5 from "../Pages/Templates/Template5/Template5";
import Template6 from "../Pages/Templates/Template6/Template6";
import Template7 from "../Pages/Templates/Template7/Template7";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSelector } from "react-redux";
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { useDispatch } from "react-redux";
import { setTheme } from "../Redux/Slices/FormSlice";

const TemplatePreview = () => {
  const dispatch = useDispatch();
  const { theme } = useSelector((state) => state.form);


  
useEffect(()=>{
   
  
  if(localStorage.getItem('theme')==-1){
  
  
    localStorage.setItem('currentIndex',theme +1 )
}
},[localStorage.getItem('theme')])

// the useeffect is changing passwed in the id because the id only change one time only and its whent the api call is secceffully

  const handleSlideChange = (swiper) => {
    const currentIndexx = swiper.realIndex ;

    dispatch(setTheme({ theme: (currentIndexx+1) })); 
    localStorage.setItem('currentIndex',currentIndexx +1)
    localStorage.setItem('theme',currentIndexx +1)
};

  const iPhoneFrame = {
    width: "100%", 
    maxWidth: "325px", 
    height: "auto",
    border: "none",
    borderRadius: "40px", // Adjusted for a more realistic look
    overflow: "hidden",
    position: "relative",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    margin: "auto", // Center the frame horizontally
    zIndex:'400'
  };

  const iPhoneScreen = {
    width: "103%", // Adjusted for responsiveness
    height: "500px", // Adjusted for responsiveness
    maxHeight: "500px", // Set a maximum height
    overflowY: "auto",
    WebkitOverflowScrolling: "touch",
    scrollbarWidth: "thin",
    scrollbarColor: "transparent transparent",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  };

  const iPhoneSpeaker = {
    height: "40px",
    backgroundColor: "black",
    position: "absolute",
    top: "-20px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "120px",
    borderRadius: "10px",
    zIndex:'500'

  };

  const iPhoneCamera = {
    height: "10px",
    backgroundColor: "black",
    position: "absolute",
    top: "8px",
    right: "16px",
    width: "10px",
    borderRadius: "50%",
    zIndex:'0'

  };

  const iPhoneButton = {
    height: "0px",
    backgroundColor: "#fff",
    position: "absolute",
    bottom: "4px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "100px",
    borderRadius: "2px",
  };
  
  const iPhoneFrameStyle = {
    ...iPhoneFrame,
    border: `10px solid black`,
  };

  const iPhoneCameraStyle = {};

  const iPhoneButtonStyle = {
    ...iPhoneButton,
    backgroundColor: "white",
  };

  const StylediPhoneFrame = ({ children }) => (
    <Box sx={iPhoneFrameStyle}>
      <div style={iPhoneSpeaker} />
      <div style={iPhoneCamera} />
      {children}
      <div style={iPhoneButtonStyle} />
    </Box>
  );

  return (
    <div className="relative">
      <StylediPhoneFrame>
        <Box sx={iPhoneScreen}>
          
         <Swiper
         initialSlide={localStorage.getItem('theme')-1}
            spaceBetween={20}
            onSlideChange={handleSlideChange}
            slidesPerView={1}
            navigation={true}
            modules={[Navigation]}
            className="mySwiper"
          >
            <SwiperSlide  > <Template2 /></SwiperSlide>
            <SwiperSlide ><Template5 /></SwiperSlide>
            <SwiperSlide><Template6 /></SwiperSlide>
            <SwiperSlide><Template7 /></SwiperSlide>
          </Swiper>
        </Box>
      </StylediPhoneFrame>
    </div>
  );
};

export default TemplatePreview;
