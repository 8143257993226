// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        Information: " Information",
        Media: "Media",
        Colors: "Colors",
        PersonalـData: "Personal Data",
        FirstـName: "First Name",
        LastـName: "Last Name",
        PhoneـNumber: "Phone Number",
        Email: "Email",
        JobـTitle: "Job Title",
        BusinessـName: "Company Name",
        Location: "Location",
        Bio: "about me",
        ProfileـPhoto: "Profile Photo",
        CoverـPhoto: "Cover Photo",
        Closeـpreview: "Close preview",
        Add_Links: "Add Links",
        Linkss: "Choose links",
        Add_Extra_Link: "Add other Link",
        Sections: "Media",
        Video_section: "Add Video",
        Pdf_section: "Add Pdf ",
        search: "Search",
        Main_background: "Main background",
        Button_background: "Button background",
        Back: "Back",

        Submit: "Submit",

        Next: "Next",
        Link_Tabs: "Link Tabs",
        ChangeـEmail: "Change Email",
        ChangeـPassword: "Change Password",
        Links: "Add Social Media Links",
        custom_input: "Custom Input",
        Colors: "Colors",
        Welcome_to_X_tag: "Welcome to X tag",
        totalـcardـTabs: "total card Tabs :",
        Link_Tabs: "Link Tabs",
        New_Email: "New Email",
        Confirm_Email: "Confirm Email",
        RESET_EMAIL: "RESET EMAIL",
        dashboard: "Dashboard",
        Email_Verification: "Email Verification",
        We_have_sent_a_code_to_your_email: "We have sent a code to your email",
        Verify_Account: "Verify Account",
        Back: "Back",
        Resend_OTP: "Resend OTP",
        Home: "Home",
        Logout: "Logout",
        Preview: "Preview",
        phoneNumSecondary: "Secondary phone number",
        save: "save and continue",
        title: "title",
        start_editing: "Start Editing",
        reenter_password: "Re enter password",
        New_password: "New password",
        Email: "Email",
        RESET_Password: "RESET Password",
        Swipe_Up_To_Preview: "Swipe Up To Preview",
        Swipe_Down_To_Preview: "Swipe Down To Close",
        alert: "Add the phone number without zero at the first",
        crop: "crop",
        Medias: "Media",
        successMessage: "Thank you for using X tag",
        infosaved: "Info saved successfully",
        returntologin: "Back to login Page",
Personal:'Personal',
Company:'Company',

      },
    },
    ar: {
      translation: {
        Information: "المعلومات",
        Media: "ميديا",
        Medias: "ميديا",
        Colors: "الألوان",
        PersonalـData: "المعلومات الشخصية",
        FirstـName: "الاسم الاول",
        LastـName: "الاسم الاخير",
        PhoneـNumber: "رقم الجوال",
        crop: "قص",
        Email: "الايميل",
        JobـTitle: "اسم المركز الوظيفي",
        BusinessـName: "اسم الشركة",
        Location: "الموقع الجغرافي",
        Bio: "عني..",
        ProfileـPhoto: "الصورة الشخصية",
        CoverـPhoto: "الصورة الخلفية",
        Closeـpreview: "تسكير البريفيو",
        Add_Links: "إضافة لينك",
        Add_Extra_Link: "إضافة لينك خارجي",
        Sections: "ميديا",
        Video_section: "إضافة فيديو ",
        Pdf_section: " pdf إضافة",
        search: "بحث",
        Linkss: "اختر الروابط",
        Main_background: "لون الخلفية",
        Button_background: "لون الأزرار",
        Back: "الرجوع",
        Submit: "التأكيد",
        Next: "التالي",
        Link_Tabs: "الضغطات عل اللينكات",
        ChangeـEmail: "تغيير البريد الإلكتروني",
        ChangeـPassword: "تغيير كلمة السر",
        Links: "روابط التواصل الاجتماعي",
        custom_input: "رابط اختياري",
        Colors: "الالوان",
        Welcome_to_X_tag: "X tag اهلا في",
        totalـcardـTabs: ":اجمالي الزيارات",
        Link_Tabs: "زيارات الروابط",
        New_Email: "البريد الإلكتروني الجديد",
        Confirm_Email: "تاكيد البريد الإلكتروني",
        RESET_EMAIL: "تعديل البريد الإلكتروني",
        dashboard: "لوحة التحكم",
        Email_Verification: "التحقق من البريد الإلكتروني",
        We_have_sent_a_code_to_your_email:
          "تم ارسال كود التحقق الى بريدك الإلكتروني",
        Verify_Account: "التحقق من الحساب",
        Back: "الرجوع",
        Resend_OTP: "اعادة ارسال الكود",
        Home: "الصفحة الرئيسية",
        Logout: "تسجيل الخروج",
        Preview: "التيمبليت",
        phoneNumSecondary: "رقم الهاتف الثاني ",
        save: " حفظ و متابعة",
        title: "العنوان",
        start_editing: "بدأ التعديل",
        reenter_password: "تأكيد كلمة السر الجديدة",
        New_password: "كلمة السر الجديدة",
        Email: "البريد الإلكتروني",
        RESET_Password: "تعديل كلمة السر",
        Swipe_Up_To_Preview: "إسحب للأعلى للعرض  ",

        Swipe_Down_To_Preview: "إسحب للأسفل لإيقاف العرض",
        alert: "يرجى عدم اضافة رقم الصفر في البداية",
        successMessage: "X tage شكرا لإستخدامك   ",
        infosaved: "لقد تم حفظ بياناتك بنجاح",
        returntologin: "العودة لتسجيل الدخول",
        Personal:'شخصي',
        Company:'أعمال',

      },
    },
    // Add more languages as needed
  },
  lng: "en", // Default language
  interpolation: {
    escapeValue: false, // React already escapes values
  },
});

export default i18n;
