import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux"; // Import the useSelector hook
import {
  setReduxSections,
  setTitleSections,
  reorderSections,
  removeSection,
  addContentToSection,
  setPdfName,
} from "../Redux/Slices/FormSlice";
import { useTranslation } from "react-i18next";

const DraggableFeatured = () => {
  const [sections, setSections] = useState([]);
  const [sectionErrors, setSectionErrors] = useState([]);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showMediaModal, setShowMediaModal] = useState(false);
  const [currentMedia, setCurrentMedia] = useState(null);
  const tex = localStorage.getItem("lann");

  const handleShowMedia = (section) => {
    setCurrentMedia(section);
    console.log("sectionsection", section);
    setShowMediaModal(true);
  };

  const handleCloseMediaModal = () => {
    setShowMediaModal(false);
    setCurrentMedia(null);
  };
  const sectionss = useSelector((state) => state.form.sections); // Use useSelector to get sections from Redux state
  useEffect(() => {
    // Set initial state based on form.sections when the component mounts
    setSections(
      (sectionss ?? []).map((section, index) => ({
        id: section.id,
        sectionName: section.sectionName,
        contentType: section.contentType,
        media: section.media
          ? section.media
          : section?.content
          ? section?.content[0]
          : "",
        pdfName: section.pdfName,
      }))
    );
  }, []);
  const handleAddSection = (contentType) => {
    if (sections.length >= 3) {
      return;
    }

    setSections((prevSections) => [
      ...prevSections,
      { sectionName: "", contentType, media: null, pdfName: "" },
    ]);
    dispatch(
      setReduxSections({
        sectionName: "",
        contentType,
        media: null,
        pdfName: "",
      })
    );
  };

  const handleRemoveSection = (sectionIndex) => {
    console.log("sections[sectionIndex]", sections[sectionIndex]);
    setSections((prevSections) => {
      const updatedSections = [...prevSections];
      updatedSections.splice(sectionIndex, 1);
      // dispatch(removeContentFromSection({ sectionIndex }));
      dispatch(removeSection(sectionIndex));

      return updatedSections;
    });
  };
  const handlePdfNameChange = (sectionIndex, value) => {
    try {
      // Dispatch the action
      dispatch(setPdfName({ sectionIndex, pdfName: value }));

      // Access the updated Redux state directly
      const updatedSections = sections.map((section, index) =>
        index === sectionIndex ? { ...section, pdfName: value } : section
      );

      // Set the updated state
      setSections(updatedSections);
    } catch (error) {
      // Handle error if needed
      console.error("Error setting PDF Name", error);
    }
  };

  const handleAddMedia = (sectionIndex, contentType) => {
    const updatedSections = sections.map((section, index) =>
      index === sectionIndex
        ? { ...section, media: { type: "media", value: null } }
        : section
    );

    setSections(updatedSections);
  };

  const handleItemChange = (sectionIndex, contentType, e) => {
    const file = e?.target?.files?.[0];

    if (!file) {
      return;
    }
    const maxSize =
      contentType === "video" ? 25 * 1024 * 1024 : 25 * 1024 * 1024; // 5 MB limit
    if (file.size > maxSize) {
      // Handle the error - file size exceeds the limit
      const newErrors = [...sectionErrors];
      newErrors[sectionIndex] = "File size exceeds the limit (25 MB).";
      setSectionErrors(newErrors);

      // You can also reset the input field value if needed
      e.target.value = "";

      return;
    }
    const newErrors = [...sectionErrors];
    newErrors[sectionIndex] = null;
    setSectionErrors(newErrors);

    const reader = new FileReader();

    reader.onload = () => {
      let base64String = btoa(
        new Uint8Array(reader.result).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );
      if (contentType === "pdf") {
        base64String = "data:application/pdf;base64," + base64String;
      } else if (contentType === "video") {
        base64String = "data:video/mp4;base64," + base64String;
      }

      const updatedSections = [...sections];
      dispatch(
        addContentToSection({ sectionIndex, contentType, media: base64String })
      );

      if (contentType === "video" || contentType === "pdf") {
        updatedSections[sectionIndex].media = {
          type: "media",
          value: base64String,
        };
      }

      setSections(updatedSections);
    };
    // dispatch(addContentToSection({ sectionIndex, contentType }));

    reader.readAsArrayBuffer(file);
  };

  const handleSectionNameChange = (sectionIndex, value) => {
    setSections((prevSections) => {
      const updatedSections = [...prevSections];
      updatedSections[sectionIndex].sectionName = value;
      dispatch(setTitleSections({ sectionIndex, value }));

      return updatedSections;
    });
  };

  const handleDragStartSection = (e, sectionIndex) => {
    e.dataTransfer.setData("text/plain", sectionIndex.toString());
  };

  const handleDropSection = (e, targetIndex) => {
    e.preventDefault();

    const draggedId = e.dataTransfer.getData("text/plain");
    const draggedIndex = parseInt(draggedId.replace("section_", ""), 10);

    if (draggedIndex === targetIndex) {
      return;
    }

    const updatedSections = [...sections];
    const [draggedSection] = updatedSections.splice(draggedIndex, 1);

    // Adjust the targetIndex to make sure it stays within the bounds
    targetIndex = Math.max(0, Math.min(targetIndex, updatedSections.length));

    updatedSections.splice(targetIndex, 0, draggedSection);

    setSections(updatedSections);
    dispatch(reorderSections({ draggedIndex, targetIndex }));
  };

  return (
    <div className="draggable-featured ">
      {sections?.map((section, sectionIndex) => {
        //  console.log('sectionsljashbdfp;kajbfsphf',sections)
        return (
          <div
            key={sectionIndex}
            className="section-box"
            draggable
            onDragStart={(e) => handleDragStartSection(e, sectionIndex)}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => handleDropSection(e, sectionIndex)}
            style={{
              padding: "0.5rem",
              borderRadius: "10px",
              margin: "2rem 0",
            }}
          >
            {/* ... other code ... */}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "0.5rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  // justifyContent:"end",
                  marginLeft: tex === "ar" ? "auto" : "0",
                  alignItems: "center",
                  width: "70%",
                }}
              >
                <label
                  htmlFor={`section_input_${sectionIndex}`}
                  style={{
                    zIndex: "20",
                    borderRadius: "25%",
                    cursor: "pointer",
                    width: "2.7rem",
                    height: "2.7rem",
                    marginTop: "0.5rem",
                    marginRight: "0.3rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: section.media ? "transparent" : "gray",
                  }}
                >
                  {section.media ? (
                    <button
                      onClick={() => handleShowMedia(section)}
                      style={{
                        background:
                          "linear-gradient(to right, rgba(229, 205, 121, 0.4), rgba(229, 205, 121, 0.7))",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "3rem",
                        height: "3rem",
                        marginTop: "0.2rem",
                        borderRadius: "25%",
                        marginRight: "0.3rem",
                        // marginl: "auto",
                      }}
                    >
                      <span
                        style={{
                          color: "white",
                          fontSize: "0.7rem",
                          padding: "0.2rem",
                          textAlign: "center",
                        }}
                      >
                        {section.contentType === "video"
                          ? "Preview"
                          : "Preview"}
                      </span>
                    </button>
                  ) : (
                    <span
                      style={{
                        color: "white",
                        fontSize: "1.5rem",
                        textAlign: "center",
                      }}
                    >
                      +
                    </span>
                  )}
                  <input
                    id={`section_input_${sectionIndex}`}
                    type="file"
                    accept={
                      section.contentType === "video" ? "video/*" : ".pdf"
                    }
                    onChange={(e) =>
                      handleItemChange(sectionIndex, section.contentType, e)
                    }
                    style={{ display: "none" }}
                    className="bg-text"
                  />
                </label>

                <TextField
                  size="small"
                  id={`section_name_${sectionIndex}`}
                  label="Title"
                  variant="outlined"
                  value={section?.sectionName}
                  onChange={(e) =>
                    handleSectionNameChange(sectionIndex, e.target.value)
                  }
                  style={{ width: "80%", marginTop: "0.53rem" }}
                  className={`bg-text ${
                    sectionErrors[sectionIndex] ? "error-border" : ""
                  }`}
                />
              </div>

              <button
                onClick={() => handleRemoveSection(sectionIndex)}
                style={{
                  background: "transparent",
                  border: "none",
                  color: "#fff",
                  cursor: "pointer",
                  fontSize: "1.4rem",
                }}
              >
                X
              </button>
            </div>
            {sectionErrors[sectionIndex] && (
              <div className="text-red-500">{sectionErrors[sectionIndex]}</div>
            )}
          </div>
        );
      })}

      <Box
        sx={{
          padding: tex === "en" ? "1rem 0.5rem" : "1rem 1.3rem",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: "1rem",
        }}
      >
        <Button
          onClick={() => handleAddSection("video")}
          component="label"
          variant="contained"
          startIcon={<AddIcon />}
          style={{
            background:
              "linear-gradient(to right, rgba(229, 205, 121, 0.1), rgba(229, 205, 121, 0.1))", // Gradient for golden color
            backdropFilter: "blur(8px)", // Apply blur effect for glassy look
            border: "1px solid rgba(229, 205, 121, 0.5)", // Golden border
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)", // Shadow for depth
            padding: "0.4rem 0.6rem",
            borderRadius: "4px",
            color: "#fff",
            cursor: "pointer",
            width: "150px", // Take full width of the grid column
            marginBottom: "1rem",
            marginTop: "0.2rem",
            marginLeft: tex === "ar" ? "auto" : "",
            fontSize: "1rem",
            letterSpacing: "0.5px",
            transition: "transform 0.3s ease",
          }}
        >
          {t("Video_section")}
        </Button>
        <Button
          onClick={() => handleAddSection("pdf")}
          component="label"
          variant="contained"
          startIcon={<AddIcon />}
          style={{
            background:
              "linear-gradient(to right, rgba(229, 205, 121, 0.1), rgba(229, 205, 121, 0.1))",
            backdropFilter: "blur(8px)",
            border: "1px solid rgba(229, 205, 121, 0.5)",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
            padding: "0.4rem 0.6rem",
            borderRadius: "4px",
            color: "#fff",
            cursor: "pointer",
            width: "150px",
            marginTop: "0.2rem",
            marginBottom: "1rem",
            marginLeft: tex === "ar" ? "auto" : "0",
            fontSize: "1rem",
            letterSpacing: "0.5px",
            transition: "transform 0.3s ease",
          }}
          disabled={
            sections.filter(
              (s) =>
                s.contentType === "pdf" ||
                (typeof s.media === "string" && s.media.slice(-3) === "pdf")
            ).length >= 2
          }
        >
          {t("Pdf_section")}
        </Button>
      </Box>
      {showMediaModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              position: "relative",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              maxWidth: "80%",
              maxHeight: "80%",
              overflowY: "auto",
            }}
          >
            {(typeof currentMedia?.media === "string" &&
              currentMedia?.media?.slice(-3) === "mp4") ||
            (typeof currentMedia?.media === "object" &&
              currentMedia?.contentType === "video") ? (
              <div>
                <video controls style={{ width: "100%", maxHeight: "500px" }}>
                  <source
                    src={
                      typeof currentMedia.media === "string"
                        ? currentMedia.media
                        : currentMedia.media.value
                    }
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
            ) : 
              (typeof currentMedia.media === "object" &&
                currentMedia?.contentType === "pdf") ? (
              <iframe
                style={{
                  width: "100%",
                  height: "50vh",
                  maxHeight: "500px",
                  minHeight: "200px",
                  overflowY: "auto",
                }}
                src={
                  typeof currentMedia.media === "string"
                    ? currentMedia.media
                    : currentMedia.media.value
                }
                title="PDF"
              ></iframe>
            ) : (typeof currentMedia.media === "string" &&
            currentMedia.media?.slice(-3) == "pdf") ?
           (   window.open(currentMedia.media) 
           
            
              )
            :""}
            <button
              onClick={handleCloseMediaModal}
              style={{
                position: "absolute",
                top: "0px",
                right: "0px",
                border: "none",
                background: "none",
                fontSize: "1.5rem",
                cursor: "pointer",
              }}
              className="text-black"
            >
              X
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DraggableFeatured;
