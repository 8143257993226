import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import ColorPicker from "../../Components/ColorPicker";

import TemplatePreview from "../../Components/TemplatePreview";
import SwiperComponent from "../../Components/Swiper";

const Step1 = () => {
  const [showPreview, setShowPreview] = useState(false);
  const tex = localStorage.getItem("lann");

  return (
    <>
      {showPreview && (
        <Box
          sx={{
            background: "#000",

            position: "fixed",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
            width: "100vw",
            height: "100vh",
            zIndex: "100",
          }}
        ></Box>
      )}{" "}
      <Box
        sx={{
          padding: "0.5rem",
          borderRadius: "10px",
          width: "100%",
          position: "sticky",
          margin: "0 auto",
          zIndex: "100",
          display: { xs: "flex", md: "none" },
          top: "0",
          right: "0",
          alignContent: "right",
          alignItems: "right",
          justifyContent: "center",
          marginRight: "1rem",
          marginTop: "16px",
        }}
      >
      
  
      </Box>
      
      <Grid spacing={2} container justifyContent={"center"}>
        <Grid item md={6} xs={12}>
        <div className="ml-[10px]">
      {/**   <SwiperComponent/>
      */}
        </div>
          <Box
            style={{
              position: "fixed",
              top: "0",
              left: "0",
              right: "0",
              width: "100%",
              height: "100%",
              zIndex: "-100",
              background: "rgb(38, 38, 38)",
            }}
          ></Box>

          <Box sx={{ padding: "1rem 0.5rem" }}>
            <Box sx={{ padding: "1rem 0.5rem", marginBottom: "1rem" }}>
              <Typography
                variant="h1"
                sx={{
                  color: "#e5cd79",
                  fontSize: "2rem",
                  textAlign: tex === "ar" ? "end" : "start",
                  fontFamily: "Robote_bold",
                  "@media (min-width: 600px)": {
                    fontSize: "3rem",
                  },
                }}
              ></Typography>
            </Box>
            <ColorPicker />
          </Box>
        </Grid>
        <Grid
        item
        md={6}
        xs={12}
        sx={{
          display: {
            xs: "block",
            md: "block",
          },
        }}
      >
        <Box
          sx={{
            position: { xs: "fixed", md: "relative" },
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            zIndex: "10000",
            width: "325px",
            backgroundColor: { xs: "#000000e8", md: "transparent" },
            overflowY: "auto",
            transition: "0.3s ease-in",
            opacity: { md: "1", sx: showPreview ? "1" : "0" },
          }}
        >
          {showPreview == true ? (
            <Box
              sx={{
                background: "#000",
                width: "100%",
                height: "100%",
                borderRadius: "20px",
              }}
            >
              <Button
                sx={{ display: "block", margin: "0 auto", zIndex: "2" }}
                variant="contained"
                onClick={() => {
                  setShowPreview(false);
                }}
                size="small"
              >
                {t("Closeـpreview")}
              </Button>
              <Box sx={{ zIndex: "100" }}>
                <TemplatePreview />
              </Box>
            </Box>
          ) : (
            ""
          )}
          <Box
            sx={{
              display: { xs: "none", md: "block" }, // Hidden on small screens, visible on medium and larger screens
              opacity: { sx: "0", md: "1" },
            }}
          >
            <TemplatePreview />
          </Box>
        </Box>
      </Grid>
      </Grid>
    </>
  );
};

export default Step1;
