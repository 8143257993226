import React, { useEffect, useState } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import StatisticBox from "../Components/StatisticBox";
import { useFetchStatsQuery } from "../Redux/API/statistics";
import axios from "axios";
import { useTranslation } from "react-i18next"; 


const StatisticsPage = () => {
  const { data, isLoading } = useFetchStatsQuery(1);
  const [dataa, setDataa] = useState([]);
  const token = localStorage.getItem('token'); // Retrieve the token from localStorage
  const profile=localStorage.getItem('profile')
  const { t } = useTranslation();

  const config = {
    headers: { Authorization: `Bearer ${token}` }

  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.dev4.gomaplus.tech/api/profile/${profile}`, config);
        setDataa(response.data.data);
    console.log('responseresponseresponses',response.data.data)
      } catch (error) {
        console.log(error)
      } finally {
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ backgroundColor: '#262626', padding: '1rem' ,minHeight: '100vh' }}>
    <Typography variant="h4" sx={{ textAlign: 'center', color: 'white', mb: 4 ,fontFamily:"Robote_bold"}}>
   {t('totalـcardـTabs')}
  </Typography>
  <Typography variant="h4" sx={{ textAlign: 'center', color: 'rgb(229, 205, 121)', mb: 4 }}>
{dataa?.views}
 </Typography>
      <Typography variant="h4" sx={{ textAlign: 'center', color: 'white', mb: 4 ,fontFamily:"Robote_bold"}}>
      {t('Link_Tabs')}    
      </Typography>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '0.3rem',
      }}>
        {data?.map((item, index) =>
         
          {
            console.log(data)
return (
          <StatisticBox
            key={index}
            number={item.view}
            name={item.name}
            photoUrl={item.logo}
          />
        ) })}
      </Box>
    </Box>
  );
};

export default StatisticsPage;
