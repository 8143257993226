import { useRef, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { AnimatePresence, motion } from "framer-motion";
import { useClickAway } from "react-use";
import { AiOutlineRollback } from "react-icons/ai";
import { BiHomeSmile } from "react-icons/bi";
import { HiOutlineChatBubbleBottomCenterText } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import PasswordIcon from "@mui/icons-material/Password";
import { useTranslation } from "react-i18next";

export const Sidebar2 = () => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  useClickAway(ref, () => setOpen(false));
  const toggleSidebar = () => setOpen((prev) => !prev);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const tex = localStorage.getItem("lann");
  return (
    <>
      <button
        onClick={toggleSidebar}
        aria-label="toggle sidebar"
        style={{
          background: "#262626",
          color: "black",
          padding: "0.6rem 0.8rem",
          borderRadius: "5px",
          textTransform: "capitalize",
          fontWeight: "bold",
          outline: "none",
          border: "1px solid #e5cd79",
          cursor: "pointer",
          fontSize: "13px",
          alignItems: "center",

          position: "relative", // To position the dropdown correctly
        }}
      >
        <GiHamburgerMenu color="#E5CD8C" />
      </button>
      <AnimatePresence mode="wait" initial={false}>
        {open && (
          <>
            <motion.div
              {...framerSidebarBackground}
              aria-hidden="true"
              className="fixed bottom-0 left-0 w-[70%] top-0 z-50 bg-[rgba(0,0,0,0.1)] backdrop-blur-sm"
              style={{ zIndex: "900" }}
            ></motion.div>
            <motion.div
              {...framerSidebarPanel}
              className="fixed top-0 bottom-0 left-0 z-50 w-full h-screen max-w-xs border-r-2 border-zinc-800 bg-zinc-900"
              ref={ref}
              aria-label="Sidebar"
              style={{ zIndex: "1000" }}
            >
              <div className="flex items-center justify-between text-[#e5cd79] p-5 border-b-2 border-zinc-800 Robote_bold">
                <span>{t("Welcome_to_X_tag")}</span>
                <button
                  onClick={toggleSidebar}
                  className="p-3 border-2 border-zinc-800 rounded-xl"
                  aria-label="close sidebar"
                >
                  <AiOutlineRollback />
                </button>
              </div>
              <ul>
                {items.map((item, idx) => {
                  const { title, href, Icon } = item;
                  return (
                    <li key={title}>
                      <div
                        onClick={() => {
                          toggleSidebar();
                          navigate(href);
                        }}
                        className={`flex items-center justify-between gap-5 p-5 text-[#e5cd79] transition-all border-b-2 hover:bg-[#000] hover: border-zinc-800 Robote_bold ${
                          tex === "ar" ? "flex-row-reverse" : ""
                        }`}
                      >
                        <motion.span {...framerText(idx)}>
                          {t(`${title}`)}
                        </motion.span>
                        <motion.div {...framerIcon}>
                          <Icon className="text-2xl" />
                        </motion.div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

const items = [
  { title: "Home", Icon: BiHomeSmile, href: "/Steper" },
  {
    title: "dashboard",
    Icon: HiOutlineChatBubbleBottomCenterText,
    href: "/dashboard",
  },
  // { title: 'Reset password', Icon: LockIcon, href: '/Reset' },
  { title: "ChangeـEmail", Icon: EmailIcon, href: "/ResetEmail" },
  { title: "ChangeـPassword", Icon: PasswordIcon, href: "/resetpassword" },
  { title: "Logout", Icon: "Login", href: "/" },
];

const framerSidebarBackground = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0, transition: { delay: 0.2 } },
  transition: { duration: 0.3 },
};

const framerSidebarPanel = {
  initial: { x: "-100%" },
  animate: { x: 0 },
  exit: { x: "-100%" },
  transition: { duration: 0.3 },
};

const framerText = (delay) => {
  return {
    initial: { opacity: 0, x: -50 },
    animate: { opacity: 1, x: 0 },
    transition: {
      delay: 0.5 + delay / 10,
    },
  };
};

const framerIcon = {
  initial: { scale: 0 },
  animate: { scale: 1 },
  transition: {
    type: "spring",
    stiffness: 260,
    damping: 20,
    delay: 1.5,
  },
};
