import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Step1 from "../Forms/Steps/StepThree";
import Step2 from "../Forms/Steps/StepOne";
import Step3 from "../Forms/Steps/StepTwo";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, StyledEngineProvider, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {
  setColors,
  setTheme,
  setProfileImage,
  setBasicInfo,
  setContacts,
  setCustomContacts,
  setCoverImage,
  setSections,
  setID,
  setIsPersonal
} from "../Redux/Slices/FormSlice";
import { useNavigate } from "react-router-dom";
import TemplatePreview from "../Components/TemplatePreview";

const steps = ["Information", "Media", "Colors"];

const StepperComponent = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const formikRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [showOverlay, setShowOverlay] = useState(true); // Initial state is true to show the overlay
  const [errorMessage, setErrorMessage] = useState("");
  const form = useSelector((state) => state.form);
  const { t } = useTranslation();
  const profile = localStorage.getItem("profile");
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tex = localStorage.getItem("lann");

  useEffect(() => {
    fetchAndDispatchProfileData();
  }, []);
  const handleErrorResponse = (error) => {
    if (!error.response) {
      setErrorMessage(
        "No response from the server. Please check your connection."
      );
    } else {
      switch (error.response.status) {
        case 500:
          setErrorMessage("Server error. Please try again later.");
          break;
        case 420:
          setErrorMessage(
            "Rate limit exceeded. Please wait and try again later."
          );
          break;
        case 422:
          setErrorMessage("Data validation error. Please check your inputs.");
          break;
        case 401:
          setErrorMessage("Unauthorized access. Please log in again.");
          break;
        default:
          setErrorMessage("An unexpected error occurred. Please try again.");
      }
    }
    console.error("Error:", error);
  };
  const handleModalClose = () => {
    setShowModal(false);
    navigate("/"); // Redirect to login page
  };
  const fetchAndDispatchProfileData = async () => {
    localStorage.setItem('theme',-1)
    if (profile === null || profile === "null") {
      return;
    }

    try {
      if (profile !== null) {
        const response = await axios.get(
          `https://api.dev4.gomaplus.tech/api/profile/${profile}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const formData = response?.data?.data;
        console.log("asdnaskjd,", formData);
        dispatch(
          setColors({
            buttonsBackground: formData?.buttonColor,
            mainBackground: formData?.bgColor,
          })
        );
        dispatch(setTheme({theme:formData?.theme.id}));
        localStorage.setItem('theme',formData?.theme.id)
        
        dispatch(setID({id:formData?.id}));
        dispatch(setIsPersonal({isPersonal:formData?.isPersonal}));
        dispatch(setProfileImage({ image: formData?.photo }));
        dispatch(setCoverImage({ coverImage: formData?.cover }));
        dispatch(
          setBasicInfo({
            basic: {
              firstName: formData?.firstName,
              lastName: formData?.lastName,
              jobTitle: formData?.jobTitle,
              businessName: formData?.businessName,
              location: formData?.location,
              bio: formData?.bio,
              phoneNumber: formData?.phoneNum,
              email: formData?.email,
              phoneNumSecondary: formData?.phoneNumSecondary,
            },
          })
        );

        formData?.primary_links?.forEach((contact) => {
          dispatch(
            setContacts({
              type: contact.name,
              value: contact.value,
              idInthback: contact.id,
              logo: contact.logo,
            })
          );
        });

        formData?.second_links?.forEach((customContact) => {
          dispatch(
            setCustomContacts({
              id: customContact.id,
              link: customContact.link,
              logo: customContact.logo,
              name_link: customContact.name_link,
            })
          );
        });

        const sectionsToAdd = formData?.section?.map((section) => ({
          id: section.id,
          sectionName: section.title,
          media: section.media,
          contentType: "video", // Assuming all sections are of type 'video'
        }));

        if (sectionsToAdd) {
          dispatch(setSections(sectionsToAdd));
        }
      }
    } catch (error) {
      handleErrorResponse(error);

      console.error("Error fetching profile data:", error);
    }
  };

  function base64ToFile(base64String, fileName, fileType) {
    if (!base64String) {
      console.error("base64String is undefined or null");
      return null;
    }

    // Ensure base64 string has the correct prefix
    const base64WithPrefix = base64String.startsWith(`data:${fileType};base64,`)
      ? base64String
      : `data:${fileType};base64,${base64String}`;

    // Split the base64 string and decode it
    const base64Content = base64WithPrefix.split(";base64,").pop();
    try {
      const byteCharacters = atob(base64Content);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new File(byteArrays, fileName, { type: fileType });
    } catch (error) {
      console.error("Error converting Base64 to File:", error);
      return null;
    }
  }

  const isBase64 = (str) => {
    // Check if the string is a base64 string
    const regex = /^data:image\/([a-zA-Z]*);base64,([^\"]*)$/;
    console.log("itsbase64", regex.test(str));
    return regex.test(str);
  };
  async function appendAndSubmitBasicInfo(formData) {
    formData.append("phoneNumSecondary", form.basic.phoneNumSecondary);
    formData.append("firstName", form.basic.firstName);
    formData.append("lastName", form.basic.lastName);
   
    if(form.theme==-1){
      formData.append("theme_id", 1);
    }
    else{
      formData.append("theme_id", form.theme);
    }
   
    if (form.coverImage !== null && form.coverImage !== "null") {
      if (isBase64(form.coverImage)) {
        formData.append(
          "cover",
          base64ToFile(form.coverImage, "image.png", "image/png")
        );
      }
    } else {
      formData.append("cover", "");
    }
    if (form.profileImage !== null && form.profileImage !== "null") {
      if (isBase64(form.profileImage)) {
        formData.append(
          "photo",
          base64ToFile(form.profileImage, "image.png", "image/png")
        );
      }
    } else {
      formData.append("photo", "");
    }
    if(form.isPersonal==null){
      formData.append("isPersonal", 'true');
    }
    else{
      formData.append("isPersonal", form.isPersonal);

    }
    formData.append("phoneNum", form.basic.phoneNumber);
    formData.append("email", form.basic.email);
    formData.append("bio", form.basic.bio);
    formData.append("location", form.basic.location);
    formData.append("jobTitle", form.basic.jobTitle);
    formData.append("businessName", form.basic.businessName);
    if(form.theme==-1){
      formData.append("theme_id", 1);
    }
    else{
      formData.append("theme_id", form.theme);
    }
       // Make API call
    if (profile == null || profile == "null") {
      await makeApiCall(formData, "create_personal_data");
    } else {
      await makeApiCall(formData, profile);
    }
  }

  async function makeApiCall(formData, api) {
    setErrorMessage(null);
    setIsLoading(true);

    try {
      const response = await axios.post(
        `https://api.dev4.gomaplus.tech/api/profile/${api}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log("Response:", response.data);
      console.log("activeStep", activeStep);
      if (activeStep == 2 && profile !== null && profile !== "null") {
        setActiveStep(0);
      } else if (activeStep == 2 && (profile == null || profile == "null")) {
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }

      setIsLoading(false);
    } catch (error) {
      handleErrorResponse(error);

      console.error("Error submitting profile data:", error);
      // handle error
      setIsLoading(false);
    }
  }
  async function appendAndSubmitContactInfo(formData, sortedContacts) {
    // Append primary and secondary contacts
    // Note: Implement sorting logic for sortedContacts if needed
    sortedContacts?.forEach((contact, index) => {
      if (contact.value !== "") {
        formData.append(
          `primaryLinks[${index}][id]`,
          contact.idInthback !== null ? contact.idInthback : index
        );
        formData.append(`primaryLinks[${index}][value]`, contact.value);
      }
    });

    form?.contactsCustom
      .filter((contact) => contact.name || contact.link)
      .filter((contact) => contact.image || contact.logo)
      .forEach((contact, index) => {
        formData.append(`secondLinks[${index}][value]`, contact.name);
        formData.append(`secondLinks[${index}][name_link]`, contact.type);
        formData.append(
          `secondLinks[${index}][link]`,
          contact.name ? contact.name : contact.link
        );

        if (isBase64(contact?.image)) {
          formData.append(
            `secondLinks[${index}][logo]`,
            base64ToFile(contact.image, "image.png", "image/png")
          );
        } else {
          formData.append(`secondLinks[${index}][logo]`, contact.logo);
        }
      });
    form.sections.forEach((section, index) => {
      if (
        (section?.media !== undefined &&
          section?.media !== null &&
          section?.media !== "undefined" &&
          section?.media !== "null" &&
          section?.media !== "" &&
          section?.sectionName !== "") ||
        (section?.content && section?.sectionName !== "")
      ) {
        if (section?.media !== null) {
          formData.append(`sections[${index}][media]`, section.media);
        } else {
          formData.append(
            `sections[${index}][media]`,
            base64ToFile(
              section.content[0].value,
              `section${index}Media`,
              section.contentType === "pdf" ? "application/pdf" : "video/mp4"
            )
          );
        }
        formData.append(`sections[${index}][title]`, section.sectionName);
        formData.append(
          `sections[${index}][name_of_file]`,
          section.sectionName
        );
      }
    });
    if(form.theme==-1){
      formData.append("theme_id", 1);
    }
    else{
      formData.append("theme_id", form.theme);
    }
   
    if (profile == null || profile == "null") {
      await makeApiCall(formData, "create_links");
    } else {
      await makeApiCall(formData, profile);
    }
  }
  async function appendAndSubmitColorInfo(formData) {
    if (
      form.colors.mainBackground == null ||
      form.colors.mainBackground == "null"
    ) {
      formData.append("bgColor", "#fff");
     

    } else {
      formData.append("bgColor", form.colors.mainBackground);
     

    }
    if (
      form.colors.buttonsBackground == null ||
      form.colors.buttonsBackground == "null"
    ) {
      formData.append("buttonColor", "#000");
    } else {
      formData.append("buttonColor", form.colors.buttonsBackground);
    }
    if(form.theme==-1){
      formData.append("theme_id", 1);
    }
    else{
      formData.append("theme_id", form.theme);
    }
       // Make API call
    if (profile == null || profile == "null") {
      await makeApiCall(formData, "create_other_data");
    } else {
      await makeApiCall(formData, profile);
    }
  }

  const handleNext = async () => {
    setErrorMessage(null);
   
    console.log("formikRef.current", formikRef.current);
    if (activeStep === 0 && formikRef.current) {
      const isValid = await formikRef.current.validateForm();
      const touchedFields = Object.keys(formikRef.current.values).reduce(
        (acc, cur) => {
          acc[cur] = true;
          return acc;
        },
        {}
      );
      formikRef.current.setTouched(touchedFields);

      if (isValid && Object.keys(isValid).length === 0) {
        const formData = new FormData();
        appendAndSubmitBasicInfo(formData);
      }
    } else if (activeStep === 1) {
      const formData = new FormData();
      const sortedContacts = form?.contacts.slice().sort((a, b) => a.id - b.id);
      appendAndSubmitContactInfo(formData, sortedContacts);
    } else if (activeStep === 2) {
      const formData = new FormData();
      appendAndSubmitColorInfo(formData);
    }
    if (
      activeStep === steps.length - 1 &&
      (profile == "null" || profile == null)
    ) {
     

      setShowModal(true);
    }
  };

  const handleBack = () => {
    setActiveStep(0);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const goToStep = (stepIndex) => {
    setActiveStep(stepIndex);
  };
  return (
    <Box sx={{ width: "100%", position: "relative", paddingBottom: "3rem" }}>
      {errorMessage !== null && errorMessage !== "" && (
        <Box
          sx={{
            backgroundColor: "red",
            color: "white",
            p: 2,
            position: "fixed",
            top: 0,
            width: "100%",
            zIndex: 1000,
          }}
        >
          {errorMessage}
        </Box>
      )}
      {/* Your other components */}
      <Modal
        open={showModal}
        onClose={handleModalClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{ bgcolor: "white", p: 4, borderRadius: 4, textAlign: "center" }}
        >
          <Typography
            variant="h6"
            id="modal-title"
            gutterBottom
            className="text-black"
          >
            {t("successMessage")}
          </Typography>
          <Typography
            variant="h6"
            id="modal-title"
            gutterBottom
            className="text-gray-600"
          >
            {t("infosaved")}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleModalClose}
          >
            {t("returntologin")}
          </Button>
        </Box>
      </Modal>

      {showOverlay && profile !== null && profile !== "null" && (
        <Box
          sx={{
            position: "absolute",
            bottom: "0px",
            left: 0,
            width: "100%",
            height: "99%",
            backgroundColor: "rgba(0, 0, 0, 0.8)", // Black background with 0.3 opacity
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
            zIndex: 600, // Ensure it's above other elements
            padding: "20px",
          }}
        >
          <Button
            variant="contained"
            onClick={() => setShowOverlay(false)} // Hide overlay when button is clicked
            sx={{
              color: "black",
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "primary.dark",
              },
            }}
          >
            {t("start_editing")}
          </Button>
        </Box>
      )}
      <Stepper
        sx={{
          padding: {
            xs: "1rem",
            sm: "2rem",
          },

          ".MuiStepLabel-label": {
            fontSize: {
              xs: "0.8rem",
              sm: "1rem",
            },
            fontFamily: "Robote_bold",
          },
          ".MuiStepIcon-root": {
            width: {
              xs: "1rem",
              sm: "2rem",
            },
            height: {
              xs: "1rem",
              sm: "2rem",
            },
          },
        }}
        activeStep={activeStep}
        nonLinear
        style={{ flexDirection: tex === "ar" ? "row-reverse" : "row" }}
      >
        {profile == null || profile == "null"
          ? steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>
                    <p>{t(label)}</p>
                  </StepLabel>
                </Step>
              );
            })
          : steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step
                  key={label}
                  {...stepProps}
                  onClick={() => goToStep(index)}
                >
                  <StepLabel
                    {...labelProps}
                    style={{
                      flexDirection: tex === "ar" ? "row-reverse" : "row",
                    }}
                  >
                    <p style={{ padding: "3px" }}>{t(label)} </p>
                  </StepLabel>
                </Step>
              );
            })}
      </Stepper>

      {activeStep === steps.length ? (
        ""
      ) : (
        <React.Fragment>
          {activeStep === 0 ? (
            <Step2 formikRef={formikRef} 
            />
          ) : activeStep === 1 ? (
            <div className="ml-2">
              <Step3 />
            </div>
          ) : activeStep === 2 ? (
            <Step1 />
          ) : (
            ""
          )}
          <StyledEngineProvider injectFirst>

          <Box
        sx={{
          
         
          visibility: { xs: "visible", md: "hidden" },
          
        }}
      >
      <TemplatePreview />
    
      </Box>
    
          </StyledEngineProvider>
          {profile == null || profile == "null" ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: tex === "ar" ? "row-reverse" : "row",
                p: 2,
                justifyContent: "space-between",
              
                marginTop:'30px',
              }}
            >
              {activeStep === 0 ? (
                <Button></Button>
              ) : (
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  style={{
                    border: "1px solid gray",
                    padding: "2px",
                    borderRadius: "4px",
                  }}
                >
                  <h4 style={{ color: "white" }}> {t("Back")}</h4>
                </Button>
              )}
              {activeStep === steps.length - 1 ? (
                <Button
                  variant="contained"
                  onClick={handleNext}
                  disabled={isLoading} // Disable the button when loading
                  style={{ position: "relative" }} // To position the loader correctly
                >
                  {isLoading ? (
                    <CircularProgress
                      size={24}
                      style={{
                        color: "gray",
                        position: "absolute", // To overlay the loader on the button
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px", // Half of the size to center it vertically
                        marginLeft: "-12px", // Half of the size to center it horizontally
                      }}
                    />
                  ) : (
                    <h4 style={{ color: "black" }}> {t("finish")} </h4>
                  )}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={handleNext}
                  disabled={isLoading} // Disable the button when loading
                  style={{ position: "relative", textAlign: "right" }} // To position the loader correctly
                >
                  {isLoading ? (
                    <CircularProgress
                      size={24}
                      style={{
                        color: "gray",
                        position: "absolute", // To overlay the loader on the button
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px", // Half of the size to center it vertically
                        marginLeft: "-12px", // Half of the size to center it horizontally
                      }}
                    />
                  ) : (
                    <h4 style={{ color: "black" }}> {t("Next")} </h4>
                  )}
                </Button>
              )}
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                p: 2,
                justifyContent: tex === "ar" ? "start" : "end",
                marginTop: {md: "-10px" },
              }}
            >
              <Button
                variant="contained"
                onClick={handleNext}
                disabled={isLoading} // Disable the button when loading
                style={{ position: "relative" }} // To position the loader correctly
              >
                {isLoading ? (
                  <CircularProgress
                    size={24}
                    style={{
                      color: "gray",
                      position: "absolute", // To overlay the loader on the button
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px", // Half of the size to center it vertically
                      marginLeft: "-12px", // Half of the size to center it horizontally
                    }}
                  />
                ) : (
                  <h4 style={{ color: "gray" }}> {t("save")} </h4>
                )}
              </Button>
            </Box>
          )}
        </React.Fragment>
      )}
      {errorMessage === "An unexpected error occurred. Please try again." ||
        (errorMessage === "Server error. Please try again later." && (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.location.reload()}
            >
              Reload Page
            </Button>
          </Box>
        ))}
    
    </Box>
  );
};

export default StepperComponent;
